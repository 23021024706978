import { TradingStatus } from "./consts";

export const getTradingStatusActionText = (tradingStatus) => {
  switch (tradingStatus) {
    case TradingStatus.ACTIVE:
      return "Deactivate";
    default:
      return "Activate";
  }
};

export const getTradingStatusColor = (tradingStatus) => {
  switch (tradingStatus) {
    case TradingStatus.ACTIVE:
      return "primary";

    case TradingStatus.INACTIVE:
      return "error";
  }
};

export const getTradingStatusRevertColor = (tradingStatus) => {
  switch (tradingStatus) {
    case TradingStatus.ACTIVE:
      return "error";

    case TradingStatus.INACTIVE:
      return "primary";
  }
};

export const getTradingStatusCellColor = (tradingStatus) => {
  switch (tradingStatus) {
    case TradingStatus.ACTIVE:
      return "#0F9052";

    case TradingStatus.INACTIVE:
      return "#D11E34";

    default:
      return "#B3804A";
  }
};
