export const PAGINATION_LIMIT = 25;

export const optionsExchange = [
  { label: "ByBit", name: "bybit" },
  { label: "Binance", name: "binance" },
];

export const optionsDirection = [
  { label: "Buy (Long)", name: "long" },
  { label: "Sell (Short)", name: "short" },
];

export const optionsTrading = [
  { label: "Active", name: "active" },
  { label: "Inactive", name: "inactive" },
  { label: "Stopping", name: "stopping" },
  { label: "Starting", name: "starting" },
];

export const optionsPortfolio = [
  { label: "Small", name: "small" },
  { label: "Medium", name: "medium" },
  { label: "High", name: "high" },
];

export const TradingStatus = {
  INACTIVE: "inactive",
  ACTIVE: "active",
  STOPPING: "stopping",
  STARTING: "starting",
};
