import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { COLORS } from "../../../styles/colors";
import { GridIcon } from "../../../assets";

const inputStyle = {
  alignItems: "center",
  borderStyle: "solid",
  borderWidth: "1px",
  borderColor: COLORS.utility.border,
  borderRadius: "3px",
  fontSize: "14px",
  fontWeight: 400,
  height: "40px",
  lineHeight: "23px",
  padding: "20px",
};

const DateRangeInput = forwardRef(({ value, onClick, ...otherProps }, ref) => (
  <>
    <input
      style={inputStyle}
      value={value}
      onClick={onClick}
      ref={ref}
      {...otherProps}
    />
    <img src={GridIcon} />
  </>
));

DateRangeInput.displayName = "DateRangeInput";

DateRangeInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
  otherProps: PropTypes.object,
};

export default DateRangeInput;
