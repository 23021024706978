import React from "react";
import PropTypes from "prop-types";
import { MenuItem, ListItemText } from "@mui/material";
import { styled } from "@mui/system";

const StyledMenuItem = styled(MenuItem)({
  paddingLeft: 0,
});

const ActionDropdownItem = ({ item, onClick }) => {
  return (
    <StyledMenuItem onClick={onClick}>
      <ListItemText primary={item} sx={{ pl: 2 }} />
    </StyledMenuItem>
  );
};

ActionDropdownItem.propTypes = {
  item: PropTypes.string,
  onClick: PropTypes.func,
};

export default ActionDropdownItem;
