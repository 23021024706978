import React from "react";
import PropTypes from "prop-types";
import { IconButton, TableCell } from "@mui/material";
import { TrashIcon } from "../../assets";

const ClientTableControl = ({ onDeleteClient }) => {
  return (
    <TableCell align="right">
      <IconButton onClick={onDeleteClient}>
        <img src={TrashIcon} alt="delete" />
      </IconButton>
    </TableCell>
  );
};

ClientTableControl.propTypes = {
  onDeleteClient: PropTypes.func,
};

export default ClientTableControl;
