import { useEffect } from "react";
import PropTypes from "prop-types";
import { useKeycloak } from "../keycloak";
import { useSelector } from "react-redux";

const PrivateRoute = ({ children }) => {
  const keycloak = useKeycloak();
  const { accessToken } = useSelector((state) => state.auth.token);

  useEffect(() => {
    const checkAuthentication = async () => {
      if (!keycloak || !keycloak.authenticated) {
        keycloak.login();
      }
    };

    checkAuthentication();
  }, [keycloak]);

  if (!keycloak || !keycloak.authenticated || !accessToken) {
    return null;
  }

  return children;
};

PrivateRoute.propTypes = {
  children: PropTypes.node,
};

export default PrivateRoute;
