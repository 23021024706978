import React from "react";
import PropTypes from "prop-types";
import { IconButton, TableCell } from "@mui/material";
import { ActivateIcon, CancelIcon, TrashIcon } from "../../assets";
import StageConnectionCheck from "./StageConnectionCheck";

const StageControls = ({
  stageId,
  status = "inactive",
  configured,
  onChageStatus,
  onDeleteStage,
}) => {
  const isInactive = status !== "inactive";

  return (
    <TableCell align="right">
      <IconButton onClick={onChageStatus}>
        <img
          src={status === "active" ? CancelIcon : ActivateIcon}
          alt="cancel"
        />
      </IconButton>
      <IconButton disabled={isInactive} onClick={onDeleteStage}>
        <img
          src={TrashIcon}
          alt="delete"
          style={{
            filter: isInactive && "contrast(0)",
            opacity: isInactive && 0.5,
          }}
        />
      </IconButton>
      {configured === "not_connected" && (
        <StageConnectionCheck stageId={stageId} />
      )}
    </TableCell>
  );
};

StageControls.propTypes = {
  stageId: PropTypes.string,
  status: PropTypes.string,
  onChageStatus: PropTypes.func,
  onDeleteStage: PropTypes.func,
  onEditStage: PropTypes.func,
  configured: PropTypes.string,
};

export default StageControls;
