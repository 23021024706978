import React from "react";
import PropTypes from "prop-types";
import { Link, TableBody, TableCell, TableRow } from "@mui/material";
import AdministrationTableSummary from "./AdministrationTableSummary";
import { useNavigate } from "react-router-dom";

const AdministrationTableBody = ({ rows = [], summary = {}, columns = [] }) => {
  const navigate = useNavigate();

  const onNavigateStage = (row) => navigate(`/stages-system/edit/${row.id}`);

  return (
    <TableBody>
      {rows.map((row) => (
        <TableRow key={row.name}>
          {Object.entries(row).map(([key, value]) => {
            switch (key) {
              case "id":
                return null;

              case "client":
                return <TableCell key={key}>{value.name}</TableCell>;

              case "exchange":
                return <TableCell key={key}>{value ?? "-"}</TableCell>;

              case "name":
                return (
                  <TableCell key={key}>
                    <Link
                      sx={{ cursor: "pointer" }}
                      onClick={() => onNavigateStage(row)}
                    >
                      {value}
                    </Link>
                  </TableCell>
                );

              case "nav":
              case "asset_sum":
              case "var_positions":
              case "var_orders":
              case "free_var":
              case "imp_loss":
                return (
                  <TableCell key={key}>{value?.toFixed(0) ?? "0"}</TableCell>
                );

              case "max_dd":
              case "max_ddd":
              case "roi_month":
              case "roi_all_time":
                return (
                  <TableCell key={key}>{value?.toFixed(2) ?? "0"}</TableCell>
                );

              default:
                return <TableCell key={key}>{value ?? "0"}</TableCell>;
            }
          })}
        </TableRow>
      ))}

      <AdministrationTableSummary summary={summary} columns={columns} />
    </TableBody>
  );
};

AdministrationTableBody.propTypes = {
  rows: PropTypes.array,
  summary: PropTypes.object,
  columns: PropTypes.array,
};

export default AdministrationTableBody;
