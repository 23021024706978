import React from "react";
import { LoaderIcon } from "../assets";
import "../styles/base.scss";
import { Grid } from "@mui/material";
import PropTypes from "prop-types";

const Loader = ({ isLoading }) => {
  if (isLoading) {
    return (
      <Grid item xs sx={{ textAlign: "center", padding: 5 }}>
        <img src={LoaderIcon} alt="Loading" className="loading" />
      </Grid>
    );
  }

  return <></>;
};

Loader.propTypes = {
  isLoading: PropTypes.bool,
};

export default Loader;
