import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { IconButton } from "@mui/material";
import {
  sssApi,
  useCheckConnectionStatusMutation,
} from "../../store/services/sss.api";
import { LoaderIcon, TestConnectionIcon } from "../../assets";
import { useTaskStatusMutation } from "../../store/services/bgt.api";
import { useDispatch } from "react-redux";

const StageConnectionCheck = ({ stageId }) => {
  const [checkStageStatus] = useCheckConnectionStatusMutation();
  const [getTaskStatus] = useTaskStatusMutation();

  const dispatch = useDispatch();

  const [taskId, setTaskId] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (taskId) {
        checkStatus();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [taskId]);

  const checkStatus = async () => {
    const result = await getTaskStatus({ task_ids: [taskId] });

    if (result.data) {
      const statuses = ["SUCCESS", "FAILED"];

      if (statuses.includes(result.data[0].status)) {
        setTaskId(null);
        dispatch(sssApi.util.invalidateTags(["StageList"]));
      }
    }
  };

  const onCheckStatus = async () => {
    try {
      const id = stageId;
      const { data } = await checkStageStatus({ id });
      setTaskId(data.task_id);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <IconButton onClick={onCheckStatus} disabled={taskId !== null}>
      {taskId === null ? (
        <img src={TestConnectionIcon} alt="check" />
      ) : (
        <img
          src={LoaderIcon}
          alt="loading"
          className="loading loading-button"
        />
      )}
    </IconButton>
  );
};

StageConnectionCheck.propTypes = {
  stageId: PropTypes.string,
};

export default StageConnectionCheck;
