import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Grid } from "@mui/material";
import { ActionModal, InputSelect, InputText } from "../";
import { Formik } from "formik";
import { useCreateStageMutation } from "../../store/services/sss.api";
import * as Yup from "yup";
import { useSearchClientByNameQuery } from "../../store/services/client.api";
import isValidDomain from "is-valid-domain";
import { optionsPortfolio } from "../../utils/consts";

const StageCreateModal = ({ isOpen, setIsOpen }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const { data, isLoading, isFetching } = useSearchClientByNameQuery({
    name: searchQuery,
  });
  const [addNewStage] = useCreateStageMutation();

  const validationSchema = Yup.object().shape({
    name: Yup.string().min(2).required("Name is required"),
    portfolio: Yup.string().required("Portfolio is required"),
    domain: Yup.string().test(
      "domain",
      "Invalid domain format. Please enter a valid domain name, such as example.com",
      function (value) {
        return isValidDomain(value);
      },
    ),
    client: Yup.string().required("Client is required"),
  });

  const clientList = data
    ? data.map((item) => ({ label: item.name, ...item }))
    : [];

  return (
    <ActionModal isOpen={isOpen} setIsOpen={setIsOpen} title={"Add new stage"}>
      <Formik
        initialValues={{
          name: "",
          client: "",
          client_id: "",
          portfolio: optionsPortfolio[0].name,
          domain: "",
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            await addNewStage(values).unwrap();
            setIsOpen(false);
          } catch (error) {
            setErrors({ client: error.data.detail });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <InputText
              label={"Stage name"}
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              error={errors.name && touched.name}
              helperText={errors.name && touched.name && errors.name}
            />
            <InputText
              label={"Domain"}
              name="domain"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.domain}
              error={errors.domain && touched.domain}
              helperText={errors.domain && touched.domain && errors.domain}
            />
            <InputSelect
              style={{ marginBottom: 20 }}
              inputLabel={"Portfolio"}
              label={"Portfolio"}
              options={optionsPortfolio}
              defaultValue={optionsPortfolio.find(
                (o) => o.name === values.portfolio,
              )}
              getSelected={(value) => setFieldValue("portfolio", value.name)}
              error={errors.portfolio && touched.portfolio}
              helperText={
                errors.portfolio && touched.portfolio && errors.portfolio
              }
              autocompleteParams={{
                getOptionLabel: (option) => option.label || "",
                isOptionEqualToValue: (option, value) =>
                  option.name === value.name,
              }}
            />
            <InputSelect
              inputLabel={"Client"}
              loading={isLoading || isFetching}
              onChange={(e) => setSearchQuery(e.target.value)}
              getSelected={(e) => {
                setFieldValue("client", e?.name || "");
                setFieldValue("client_id", e?.id || "");
              }}
              onBlur={() => setSearchQuery("")}
              options={clientList}
              error={errors.client && touched.client}
              helperText={errors.client && touched.client && errors.client}
              sx={{ mb: 3 }}
            />
            <Grid container columnGap={2}>
              <Grid item xs>
                <Button
                  onClick={() => setIsOpen(false)}
                  fullWidth
                  variant="outlined"
                  sx={{ borderWidth: 2 }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs>
                <Button type="submit" fullWidth variant="contained">
                  Add Stage
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </ActionModal>
  );
};

StageCreateModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
};

export default StageCreateModal;
