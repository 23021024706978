import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/system";
import { InputText, InputSelect } from "../../";
import { COLORS } from "../../../styles/colors";
import { useSearchClientByNameQuery } from "../../../store/services/client.api";
import getSystemStatus from "../../../utils/stages/getSystemStatus";

const StyledTypography = styled(Typography)({
  fontSize: 14,
  marginBottom: 10,
  color: COLORS.text.secondary,
});

const Status = styled(Typography)({
  display: "inline",
  fontSize: 14,
  color: COLORS.text.primary,
  marginLeft: 5,
});

const Container = styled(Grid)({
  marginTop: "15px",
});

const StageInformation = ({
  handleChange,
  handleBlur,
  values,
  setFieldValue,
  errors,
  touched,
}) => {
  const [clientQuery, setClientQuery] = useState("");
  const { data, isLoading } = useSearchClientByNameQuery({ name: clientQuery });

  const getHelperText = (key) => {
    return errors[key] && touched[key] && errors[key];
  };

  const getClientId = (e) => {
    setFieldValue("client", { id: e?.id, name: e?.name });
    setFieldValue("client_id", e?.id || "");
  };

  const clientOptions =
    data?.map((item) => ({ label: item.name, ...item })) ?? [];

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        Stage information
      </AccordionSummary>
      <AccordionDetails>
        <Container container columnSpacing={2} columns={4}>
          <Grid item xs={1}>
            <InputText
              label={"Stage name"}
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values?.name}
              error={errors.name && touched.name}
              helperText={getHelperText("name")}
            />
          </Grid>
          <Grid item xs={1}>
            <InputSelect
              loading={isLoading}
              inputLabel={"Client"}
              defaultValue={values?.client}
              value={values?.client}
              getSelected={getClientId}
              onChange={(e) => setClientQuery(e.target.value)}
              options={clientOptions}
              error={errors.client_id && touched.client_id}
              helperText={getHelperText("client_id")}
              autocompleteParams={{
                getOptionLabel: (option) => option.name || "",
                isOptionEqualToValue: (option, value) => option.id === value.id,
              }}
            />
          </Grid>
          <Grid item xs={1}>
            <InputText
              label={"Domain"}
              name="domain"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values?.domain}
              error={errors.domain && touched.domain}
              helperText={getHelperText("domain")}
            />
          </Grid>
          <Grid item xs={1}>
            <StyledTypography component={"div"}>
              System status:
              <Status>{getSystemStatus(values?.system_status) || "-"}</Status>
            </StyledTypography>

            <StyledTypography component={"div"}>
              Q-Active Strategies:
              <Status>{values?.q_active_strategies || "0"}</Status>
            </StyledTypography>

            <StyledTypography component={"div"}>
              Start date:
              <Status>{values?.start_date || "-"}</Status>
            </StyledTypography>

            <StyledTypography component={"div"}>
              Q-days:
              <Status>{values?.q_days || "-"}</Status>
            </StyledTypography>

            <StyledTypography component={"div"}>
              Keycloak Client ID:
              <Status>{values?.keycloak_client_id || "-"}</Status>
            </StyledTypography>
          </Grid>
        </Container>
      </AccordionDetails>
    </Accordion>
  );
};

StageInformation.propTypes = {
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
};

export default StageInformation;
