import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { PAGINATION_LIMIT } from "../../utils/consts";
import { compareObjects } from "../../utils/compare";

export const apaApi = createApi({
  reducerPath: "apaApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_URL,
    prepareHeaders: (headers, { getState }) => {
      headers.set("Content-Type", "application/json");

      const token = getState().auth.token.accessToken;

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getSignalsList: builder.query({
      query: ({
        limit = PAGINATION_LIMIT,
        offset = 0,
        name = [],
        client = [],
        strategy = [],
        ticker = [],
        direction = "",
        exchange = "",
        orderBy = "name",
      }) => {
        const directionFilter = direction ? `&direction=${direction}` : "";
        const exchangeFilter = exchange ? `&exchange=${exchange}` : "";
        const stageFilter = name.length
          ? "&" + name.map((i) => `stage_id=${i}`).join("&")
          : "";
        const clientFilter = client.length
          ? "&" + client.map((i) => `client_id=${i}`).join("&")
          : "";
        const tickerFilter = ticker.length
          ? "&" + ticker.map((i) => `ticker=${i}`).join("&")
          : "";
        const strategyFilter = strategy.length
          ? "&" + strategy.map((i) => `strategy=${i}`).join("&")
          : "";
        const order = orderBy ? `&order_by=${orderBy}` : "";

        return `/apa/signals?limit=${limit}&offset=${offset}${order}${stageFilter}${clientFilter}${tickerFilter}${strategyFilter}${directionFilter}${exchangeFilter}`;
      },
      method: "GET",
      forceRefetch: ({ currentArg, previousArg }) => {
        return !compareObjects(currentArg, previousArg);
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, { arg }) => {
        if (arg.offset == 0) {
          return {
            rows: newItems.rows,
            isNextPage: newItems.isNextPage,
            summary: newItems.summary,
          };
        }
        return {
          rows: [...currentCache.rows, ...newItems.rows],
          summary: newItems.summary,
          isNextPage: newItems.isNextPage,
        };
      },
      transformResponse: (response) => {
        return {
          rows: response.rows,
          summary: response.summary,
          isNextPage: response?.rows.length > 0,
        };
      },
      providesTags: ["SignalList"],
    }),
    syncSignalsList: builder.mutation({
      query: () => ({
        url: "/apa/signals/sync",
        method: "POST",
      }),
    }),
    searchSignalByStrategy: builder.query({
      query: ({ strategy = "" }) =>
        `/apa/signals/strategies?limit=5&strategy=${strategy}`,
      method: "GET",
      forceRefetch: ({ currentArg, previousArg }) => {
        return currentArg !== previousArg;
      },
    }),
    searchSignalByStage: builder.query({
      query: () => `/apa/signals/stages`,
      method: "GET",
      forceRefetch: ({ currentArg, previousArg }) => {
        return currentArg !== previousArg;
      },
    }),
    searchSignalByTicker: builder.query({
      query: ({ ticker = "" }) =>
        `/apa/signals/tickers?limit=5&ticker=${ticker}`,
      method: "GET",
      forceRefetch: ({ currentArg, previousArg }) => {
        return currentArg !== previousArg;
      },
    }),
    skipCheckExit: builder.mutation({
      query(body) {
        return {
          url: `/apa/signals/skip-check-k-exit`,
          method: "POST",
          body: JSON.stringify(body),
        };
      },
      invalidatesTags: ["ApaList"],
    }),
  }),
});

export const {
  useGetSignalsListQuery,
  useSyncSignalsListMutation,
  useSearchSignalByStageQuery,
  useSearchSignalByStrategyQuery,
  useSearchSignalByTickerQuery,
  useSkipCheckExitMutation,
} = apaApi;
