import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, TableBody, TableCell, TableRow } from "@mui/material";
import ClientTableControl from "./ClientTableControls";
import ClientDeleteModal from "./ClientDeleteModal";
import { useNavigate } from "react-router-dom";

const ClientTableBody = ({ rows }) => {
  const navigate = useNavigate();
  const [openDeleteModal, setDeleteModalOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);

  const onDeleteClient = (row) => {
    setSelectedClient(row);
    setDeleteModalOpen(true);
  };

  const onNavigateClient = (row) => navigate(`stages/${row.id}`);

  return (
    <TableBody>
      {rows?.map((row) => (
        <TableRow key={row.id}>
          <TableCell>
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => onNavigateClient(row)}
            >
              {row.name}
            </Link>
          </TableCell>
          <TableCell>{row.stages_count}</TableCell>
          <ClientTableControl onDeleteClient={() => onDeleteClient(row)} />
        </TableRow>
      ))}
      <ClientDeleteModal
        isOpen={openDeleteModal}
        setIsOpen={() => setDeleteModalOpen(!openDeleteModal)}
        id={selectedClient?.id}
      />
    </TableBody>
  );
};

ClientTableBody.propTypes = {
  rows: PropTypes.array,
};

export default ClientTableBody;
