import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid, IconButton, Typography } from "@mui/material";
import { CancelIcon, ActivateIcon } from "../../assets";
import { StageBulkStatusModal } from "..";
import { TradingStatus } from "../../utils/consts";

const StageTableSelected = ({ selectedRows = [] }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [statusModal, setStatusModal] = useState(true);

  const openChangeStatusModal = (status) => {
    setStatusModal(status);
    setModalVisible(true);
  };

  const showDeactivateButton = () => {
    if (!selectedRows.length) {
      return false;
    }

    return selectedRows.every(
      (row) => row.trading_status === TradingStatus.ACTIVE,
    );
  };

  const showActivateButton = () => {
    if (!selectedRows.length) {
      return false;
    }

    return selectedRows.every(
      (row) =>
        row.trading_status === TradingStatus.INACTIVE ||
        row.trading_status === TradingStatus.STOPPING,
    );
  };

  return (
    <Grid item xs style={{ display: "flex", alignItems: "center" }}>
      <Typography>{selectedRows.length} selected</Typography>

      {showDeactivateButton() && (
        <IconButton onClick={() => openChangeStatusModal(TradingStatus.ACTIVE)}>
          <img src={CancelIcon} alt="stopping" />
        </IconButton>
      )}

      {showActivateButton() && (
        <IconButton
          onClick={() => openChangeStatusModal(TradingStatus.INACTIVE)}
        >
          <img src={ActivateIcon} alt="activate" />
        </IconButton>
      )}

      <StageBulkStatusModal
        isOpen={modalVisible}
        setIsOpen={setModalVisible}
        stages={selectedRows}
        status={statusModal}
      />
    </Grid>
  );
};

StageTableSelected.propTypes = {
  selectedRows: PropTypes.array,
};

export default StageTableSelected;
