import React from "react";
import PropTypes from "prop-types";
import { Checkbox, TableCell } from "@mui/material";
import { styled } from "@mui/system";

const StyledCell = styled(TableCell)({
  width: 50,
  padding: 0,
  textAlign: "center",
});

const StageTableHeaderCheckbox = ({
  allRows = [],
  selectedRows = [],
  setSelectedRows,
}) => {
  const isChecked =
    selectedRows.length === allRows.length && selectedRows.length > 0;

  const isIndeterminate =
    selectedRows.length > 0 && selectedRows.length !== allRows.length;

  const handleSelectedRow = () => {
    if (selectedRows.length === allRows.length) {
      return setSelectedRows([]);
    }

    setSelectedRows(allRows);
  };

  return (
    <StyledCell padding="checkbox">
      <Checkbox
        onClick={handleSelectedRow}
        checked={isChecked}
        indeterminate={isIndeterminate}
      />
    </StyledCell>
  );
};

StageTableHeaderCheckbox.propTypes = {
  allRows: PropTypes.array,
  selectedRows: PropTypes.array,
  setSelectedRows: PropTypes.func,
};

export default StageTableHeaderCheckbox;
