import React from "react";
import PropTypes from "prop-types";
import { Button, Grid, Typography } from "@mui/material";
import { ActionModal } from "../";
import { useSkipCheckExitMutation } from "../../store/services/apa.api";

const ChangeByDistance = ({ state, isOpen, setIsOpen, selectedRows }) => {
  const [skipCheck, { isLoading }] = useSkipCheckExitMutation();

  const handleCloseAction = async () => {
    try {
      await skipCheck({
        signal_ids: selectedRows.map((row) => row.id),
        skip_check_by_distance: state === true ? false : true,
      });
      setIsOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ActionModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={`${state === true ? "Deactivate" : "Activate"} kExit by distance`}
    >
      <>
        <Typography sx={{ mb: 3, fontSize: 14 }}>
          You are going to {state === true ? "deactivate" : "activate"} kExit by
          distance for all the chosen positions. Are you sure?
        </Typography>

        <Grid container columnGap={2}>
          <Grid item xs>
            <Button
              onClick={() => setIsOpen(false)}
              fullWidth
              variant="outlined"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs>
            <Button
              onClick={handleCloseAction}
              fullWidth
              variant="contained"
              disabled={isLoading}
            >
              Confirm
            </Button>
          </Grid>
        </Grid>
      </>
    </ActionModal>
  );
};

ChangeByDistance.propTypes = {
  state: PropTypes.bool,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  selectedRows: PropTypes.array,
};

export default ChangeByDistance;
