import React from "react";
import PropTypes from "prop-types";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  InputLabel,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/system";
import { InputText, InputSelect } from "../../";
import { FieldArray } from "formik";
import { AddIcon, DeleteIcon } from "../../../assets";
import ReactInputMask from "react-input-mask";

const StyledLabel = styled(InputLabel)({
  fontSize: 14,
  marginBottom: "5px",
});

const Container = styled(Grid)({
  marginTop: "15px",
});

const DeleteButton = styled(Grid)({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
});

const CreateButton = styled(Button)({
  minWidth: "40px",
  padding: 0,
});

const ReportSettings = ({
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  setFieldValue,
}) => {
  const getErrorMessage = (errors, touched, index) => {
    if (
      errors?.report_schedulers?.[index]?.schedule_value &&
      touched?.report_schedulers?.[index]?.schedule_value
    ) {
      return errors.report_schedulers[index].schedule_value;
    }

    return null;
  };

  const getError = (errors, touched, index) => {
    return errors?.report_schedulers?.[index]?.schedule_value &&
      touched?.report_schedulers?.[index]?.schedule_value
  }

  const handleScheduleTypeChange = (index, newType, setFieldValue) => {
    setFieldValue(`report_schedulers.${index}.schedule_type`, newType);
    setFieldValue(`report_schedulers.${index}.schedule_value`, "");
  };

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        Client report settings
      </AccordionSummary>
      <AccordionDetails>
        {/* Report config */}
        <Container container columnSpacing={2} columns={4}>
          <Grid item xs={1}>
            <InputText
              label={"Chat ID"}
              name="investor_chat_id"
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!values?.investor_bot_token}
              value={values?.investor_chat_id || ""}
            />
          </Grid>
          <Grid item xs={1}>
            <InputText
              label={"Bot API token"}
              name="investor_bot_token"
              onChange={handleChange}
              placeholder={"******************************"}
              onBlur={handleBlur}
              value={values?.investor_bot_token || ""}
            />
          </Grid>
        </Container>

        {/* Report scheduler */}
        <FieldArray name="report_schedulers">
          {({ remove, push }) => (
            <div>
              {values?.report_schedulers?.map((scheduler, index) => (
                <Grid container columnSpacing={2} columns={4} key={index}>
                  <Grid item xs={1}>
                    <StyledLabel>Period</StyledLabel>
                    <InputSelect
                      label={"Period"}
                      options={["daily", "weekly", "monthly", "custom"]}
                      value={scheduler.schedule_type}
                      defaultValue={scheduler.schedule_type}
                      getSelected={(value) => handleScheduleTypeChange(index, value, setFieldValue)}
                      disabled={!scheduler.isNew}
                    />
                  </Grid>
                  <Grid item>
                    {scheduler.schedule_type == "custom" ? (
                      <InputText
                        label={"Report time"}
                        placeholder={"* * * *"}
                        name={`report_schedulers.${index}.schedule_value`}
                        value={scheduler.schedule_value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={getError(errors, touched, index)}
                        helperText={getErrorMessage(errors, touched, index)}
                        disabled={!scheduler.isNew}
                      />
                    ) : (
                      <ReactInputMask
                        mask="23:59"
                        maskChar={null}
                        formatChars={{
                          '2': '[0-2]',
                          '3': '[0-3]',
                          '5': '[0-5]',
                          '9': '[0-9]',
                        }}
                        value={scheduler.schedule_value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={!scheduler.isNew}
                      >
                        {() =>
                          <InputText
                            label={"Report time"}
                            placeholder={"00:00"}
                            name={`report_schedulers.${index}.schedule_value`}
                          />
                        }
                      </ReactInputMask>
                    )}
                  </Grid>
                  <DeleteButton item onClick={() => remove(index)}>
                    <img src={DeleteIcon} alt="Delete" />
                  </DeleteButton>
                </Grid>
              ))}

              <CreateButton
                type="button"
                variant="contained"
                disabled={
                  !(values.investor_bot_token && values.investor_chat_id)
                }
                onClick={() =>
                  push({ schedule_type: "", schedule_value: "", isNew: true })
                }
              >
                <img src={AddIcon} alt="Create" />
              </CreateButton>
            </div>
          )}
        </FieldArray>
      </AccordionDetails>
    </Accordion >
  );
};

ReportSettings.propTypes = {
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
};

export default ReportSettings;
