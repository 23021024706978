import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import NavigationItem from "./NavigationItem";
import { COLORS } from "../styles/colors";
import {
  AmdMenuIcon,
  ApaMenuIcon,
  CloseMenuIcon,
  EpxandMenuIcon,
  SssMenuIcon,
  LogoutMenuIcon,
  ClientMenuIcon,
} from "../assets";
import { useLocation, useNavigate } from "react-router-dom";
import "../styles/menu.scss";
import { useKeycloak } from "../keycloak";
import SSENotification from "./SSENotification";

const drawerWidth = 360;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "end",
  padding: theme.spacing(0, 1),
  borderBottom: `1px solid ${COLORS.text.secondary}`,
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const ToggleButton = styled(IconButton)({
  "&:hover": {
    backgroundColor: COLORS.base.tertiary,
  },
});

export default function Navigation({ children }) {
  const keycloak = useKeycloak();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleDrawerClose = () => {
    setOpen(!open);
  };

  const handleNavigation = (link) => {
    navigate(link);
  };

  const handleLogout = () => {
    keycloak.logout();
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <ToggleButton onClick={handleDrawerClose}>
            {open ? <img src={CloseMenuIcon} /> : <img src={EpxandMenuIcon} />}
          </ToggleButton>
        </DrawerHeader>
        <List sx={{ height: "100%" }}>
          <NavigationItem
            handleClick={() => handleNavigation("/")}
            open={open}
            label="Administration master data (AMD)"
            icon={
              <img
                src={AmdMenuIcon}
                className={pathname === "/" ? "menu-icon-active" : undefined}
              />
            }
          />
          <NavigationItem
            handleClick={() => handleNavigation("/active-position")}
            open={open}
            label="Active position administration (APA)"
            icon={
              <img
                src={ApaMenuIcon}
                className={
                  pathname === "/active-position"
                    ? "menu-icon-active"
                    : undefined
                }
              />
            }
          />
          <NavigationItem
            handleClick={() => handleNavigation("/stages-system")}
            open={open}
            label="Stages system settings (SSS)"
            icon={
              <img
                src={SssMenuIcon}
                className={
                  pathname.startsWith("/stages-system")
                    ? "menu-icon-active"
                    : undefined
                }
              />
            }
          />
          <NavigationItem
            handleClick={() => handleNavigation("/clients")}
            open={open}
            label="Clients"
            icon={
              <img
                src={ClientMenuIcon}
                className={
                  pathname.startsWith("/clients")
                    ? "menu-icon-active"
                    : undefined
                }
              />
            }
          />
          <div className="menu-logout-button">
            <NavigationItem
              handleClick={() => handleLogout()}
              open={open}
              label="Sign Out"
              icon={<img src={LogoutMenuIcon} />}
            />
          </div>
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 2, bgcolor: COLORS.utility.background }}
      >
        <SSENotification />
        {children}
      </Box>
    </Box>
  );
}

Navigation.propTypes = {
  active: PropTypes.string,
  children: PropTypes.node,
};
