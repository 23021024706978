import React from "react";
import PropTypes from "prop-types";
import { Alert, Snackbar, Typography } from "@mui/material";

const AlertStyle = {
  width: "400px",
  borderRadius: 2,
  bgcolor: "white",
};

const TitleStyle = {
  fontSize: "18px",
  lineHeight: "18px",
  textTransform: "capitalize",
  fontWeight: 700,
  bgcolor: "white",
};

const TooltipModal = ({
  isOpen = false,
  status = "success",
  title,
  eventId,
  deleteEventById,
}) => {
  const [open, setOpen] = React.useState(isOpen);

  const handleSetOpen = (value) => {
    deleteEventById(eventId);
    setOpen(value);
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      autoHideDuration={3000}
      open={open}
      onClose={handleSetOpen}
    >
      <Alert
        severity={status === "success" ? "success" : "error"}
        variant="outlined"
        sx={AlertStyle}
      >
        <Typography
          sx={{ ...TitleStyle, color: status === "success" ? "green" : "red" }}
        >
          {status}
        </Typography>
        {title}
      </Alert>
    </Snackbar>
  );
};

TooltipModal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  status: PropTypes.string,
  eventId: PropTypes.string,
  deleteEventById: PropTypes.func,
};

export default TooltipModal;
