import React from "react";
import PropTypes from "prop-types";
import { Button, Grid, Typography } from "@mui/material";
import { ActionModal } from "../";
import { useDeleteClientByIdMutation } from "../../store/services/client.api";

const ClientDeleteModal = ({ isOpen, setIsOpen, id }) => {
  const [deleteClientById, { isLoading }] = useDeleteClientByIdMutation();

  const handleDeleteClient = async () => {
    try {
      await deleteClientById(id);
      setIsOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ActionModal isOpen={isOpen} setIsOpen={setIsOpen} title={"Delete client"}>
      <>
        <Typography sx={{ mb: 3, fontSize: 14 }}>
          Are you sure you want to delete this client?
        </Typography>

        <Grid container columnGap={2}>
          <Grid xs item>
            <Button
              onClick={() => setIsOpen(false)}
              fullWidth
              variant="outlined"
            >
              Cancel
            </Button>
          </Grid>
          <Grid xs item>
            <Button
              onClick={handleDeleteClient}
              fullWidth
              variant="contained"
              color="error"
              disabled={isLoading}
            >
              Delete
            </Button>
          </Grid>
        </Grid>
      </>
    </ActionModal>
  );
};

ClientDeleteModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  id: PropTypes.string,
};

export default ClientDeleteModal;
