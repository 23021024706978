import React from "react";

import { Provider } from "react-redux";
import { store } from "./store";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { style } from "./styles/theme";
import Navigation from "./navigation";
import { KeycloakProvider } from "./keycloak";

const theme = createTheme(style);

const App = () => {
  return (
    <Provider store={store}>
      <KeycloakProvider>
        <ThemeProvider theme={theme}>
          <Navigation />
        </ThemeProvider>
      </KeycloakProvider>
    </Provider>
  );
};

export default App;
