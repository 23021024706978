import React from "react";
import PropTypes from "prop-types";
import { Button, Grid, Typography } from "@mui/material";
import { ActionModal } from "../";
import { useDeleteStageByIdMutation } from "../../store/services/sss.api";

const StageDeleteModal = ({ isOpen, setIsOpen, stage }) => {
  const [deleteStageById, { isLoading }] = useDeleteStageByIdMutation();

  const handleDeleteStage = async () => {
    try {
      await deleteStageById(stage?.id);
      setIsOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ActionModal isOpen={isOpen} setIsOpen={setIsOpen} title={"Delete Stages"}>
      <>
        <Typography sx={{ mb: 3, fontSize: 14 }}>
          Are you sure you want to delete stage {stage?.name}
        </Typography>

        <Grid container columnGap={2}>
          <Grid item xs>
            <Button
              onClick={() => setIsOpen(false)}
              fullWidth
              variant="outlined"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs>
            <Button
              onClick={handleDeleteStage}
              fullWidth
              variant="contained"
              color="error"
              disabled={isLoading}
            >
              Delete
            </Button>
          </Grid>
        </Grid>
      </>
    </ActionModal>
  );
};

StageDeleteModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  stage: PropTypes.object,
};

export default StageDeleteModal;
