import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { PAGINATION_LIMIT } from "../../utils/consts";
import { compareObjects } from "../../utils/compare";

export const clientApi = createApi({
  reducerPath: "clientApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_URL,
    prepareHeaders: (headers, { getState }) => {
      headers.set("Content-Type", "application/json");

      const token = getState().auth.token.accessToken;

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getClientList: builder.query({
      query: ({ limit = PAGINATION_LIMIT, offset = 0, orderBy = "name" }) => {
        const order = orderBy ? `&order_by=${orderBy}` : "";

        return `/sss/clients?limit=${limit}&offset=${offset}${order}`;
      },
      method: "GET",
      forceRefetch: ({ currentArg, previousArg }) => {
        return !compareObjects(currentArg, previousArg);
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, { arg }) => {
        if (arg.offset == 0) {
          return {
            rows: newItems.rows,
            isNextPage: newItems.isNextPage,
          };
        }
        return {
          rows: [...currentCache.rows, ...newItems.rows],
          isNextPage: newItems.isNextPage,
        };
      },
      transformResponse: (response) => {
        return {
          rows: response,
          isNextPage: response?.length > 0,
        };
      },
    }),
    getClientById: builder.query({
      query: (id) => `/sss/clients/${id}`,
      method: "GET",
    }),
    createClient: builder.mutation({
      query(body) {
        return {
          url: "/sss/clients",
          method: "POST",
          body: JSON.stringify(body),
        };
      },
    }),
    deleteClientById: builder.mutation({
      query(body) {
        return {
          url: `/sss/clients/${body}`,
          method: "DELETE",
        };
      },
    }),
    updateClientById: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `/sss/clients/${id}`,
          method: "PUT",
          body: JSON.stringify(body),
        };
      },
    }),
    searchClientByName: builder.query({
      query: ({ name = "" }) => `/sss/clients?limit=5&name=${name}`,
      method: "GET",
      forceRefetch: ({ currentArg, previousArg }) => {
        return currentArg !== previousArg;
      },
    }),
    searchStageByName: builder.query({
      query: ({ name = "", id }) =>
        `/sss/stages?limit=5&client_id=${id}&name=${name}`,
      method: "GET",
      forceRefetch: ({ currentArg, previousArg }) => {
        return currentArg !== previousArg;
      },
    }),
    getClientFilter: builder.query({
      query: ({ limit = 50, offset = 0, name }) =>
        `/sss/clients/filter?limit=${limit}&offset=${offset}&name=${name}`,
      method: "GET",
      forceRefetch: ({ currentArg, previousArg }) => {
        return !compareObjects(currentArg, previousArg);
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, { arg }) => {
        if (arg.offset == 0) {
          currentCache = [];

          return {
            client: newItems.client,
            isNextPage: newItems.isNextPage,
          };
        }
        return {
          client: [...currentCache.client, ...newItems.client],
          isNextPage: newItems.isNextPage,
        };
      },
      transformResponse: (response) => {
        return {
          client: response,
          isNextPage: response?.length > 0,
        };
      },
    }),
  }),
});

export const {
  useGetClientListQuery,
  useGetClientByIdQuery,
  useCreateClientMutation,
  useDeleteClientByIdMutation,
  useUpdateClientByIdMutation,
  useSearchClientByNameQuery,
  useSearchStageByNameQuery,
  useGetClientFilterQuery,
} = clientApi;
