import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const bgtApi = createApi({
  reducerPath: "bgtApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_URL,
    prepareHeaders: (headers, { getState }) => {
      headers.set("Content-Type", "application/json");

      const token = getState().auth.token.accessToken;

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    taskStatus: builder.mutation({
      query(body) {
        return {
          url: `/bgt/tasks/status`,
          method: "POST",
          body: JSON.stringify(body),
        };
      },
    }),
  }),
});

export const { useTaskStatusMutation } = bgtApi;
