import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ActionModal } from "../";
import { useBulkUpdateStageStatusMutation } from "../../store/services/sss.api";
import {
  getTradingStatusActionText,
  getTradingStatusRevertColor,
} from "../../utils/strings";
import { TradingStatus } from "../../utils/consts";

const StageActivateModal = ({
  isOpen,
  setIsOpen,
  status = TradingStatus.ACTIVE,
  stages = [],
}) => {
  const [updateStageStatus, { isLoading }] = useBulkUpdateStageStatusMutation();
  console.log(status);
  const onChangeStatus = async () => {
    const newStatus =
      status === TradingStatus.ACTIVE
        ? TradingStatus.STOPPING
        : TradingStatus.STARTING;

    let payload = [];
    for (let stage of stages) {
      payload.push({ stage_id: stage.id, new_trading_status: newStatus });
    }

    try {
      await updateStageStatus(payload);
      setIsOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ActionModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={`${getTradingStatusActionText(status)} stages`}
    >
      <>
        <Typography sx={{ mb: 3, fontSize: 14 }}>
          {`You are going to ${getTradingStatusActionText(
            status,
          ).toLowerCase()} stages:`}
        </Typography>

        <Table sx={{ mb: 3 }}>
          <TableHead>
            <TableRow>
              <TableCell>Stage name</TableCell>
              <TableCell>Asset sum</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stages.map((stage) => (
              <TableRow key={stage.id}>
                <TableCell>{stage?.name}</TableCell>
                <TableCell>-</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Grid container columnGap={2}>
          <Grid item xs>
            <Button
              onClick={() => setIsOpen(false)}
              fullWidth
              variant="outlined"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs>
            <Button
              onClick={() => onChangeStatus()}
              fullWidth
              variant="contained"
              color={getTradingStatusRevertColor(status)}
              disabled={isLoading}
            >
              {getTradingStatusActionText(status)}
            </Button>
          </Grid>
        </Grid>
      </>
    </ActionModal>
  );
};

StageActivateModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  stages: PropTypes.array,
  status: PropTypes.bool,
};

export default StageActivateModal;
