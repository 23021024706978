import React from "react";
import PropTypes from "prop-types";
import "../styles/base.scss";
import { COLORS } from "../styles/colors";

const STATUS_MAP = {
  not_connected: { text: "Not Connected", color: COLORS.status.danger },
  not_configured: { text: "Not Configured", color: COLORS.status.warning },
  configured: { text: "Configured", color: COLORS.status.success },
};

const SystemStatus = ({ status }) => {
  const { text, color } = STATUS_MAP[status] || STATUS_MAP.configured;

  return (
    <div className="system-status">
      <span className="status-indicator" style={{ background: color }} /> {text}
    </div>
  );
};

SystemStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default SystemStatus;