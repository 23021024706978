import React from "react";
import PropTypes from "prop-types";
import { Button, Grid, Typography } from "@mui/material";
import { ActionModal, InputText } from "../";
import { useCloseLimitMutation } from "../../store/services/amd.api";
import { Formik } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  coeff: Yup.number()
    .min(0.01)
    .max(0.99)
    .typeError("Coefficient should be a number")
    .required(),
});

const CloseLimitModal = ({ isOpen, setIsOpen, selectedRows }) => {
  const [closeLimit, { isLoading }] = useCloseLimitMutation();

  return (
    <ActionModal isOpen={isOpen} setIsOpen={setIsOpen} title={"Close (limit)"}>
      <>
        <Typography sx={{ mb: 3, fontSize: 14 }}>
          You are going to close all the chosen positions by limit order. Please
          enter new coefficient:
        </Typography>

        <Formik
          initialValues={{
            coeff: 0,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            try {
              await closeLimit({
                signal_ids: selectedRows.map((row) => row.id),
                coefficient: values.coeff,
              });
              setIsOpen(false);
            } catch (error) {
              console.log(error);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid item xs>
                <InputText
                  label={"New coefficient"}
                  name="coeff"
                  placeholder="0"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.coeff}
                  error={errors.coeff && touched.coeff}
                  helperText={errors.coeff && touched.coeff && errors.coeff}
                />
              </Grid>

              <Grid container columnGap={2}>
                <Grid item xs>
                  <Button
                    onClick={() => setIsOpen(false)}
                    fullWidth
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={isLoading || isSubmitting || !isValid}
                  >
                    Confirm
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </>
    </ActionModal>
  );
};

CloseLimitModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  selectedRows: PropTypes.array,
};

export default CloseLimitModal;
