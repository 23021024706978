import React from "react";
import PropTypes from "prop-types";
import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import TableHeaderCheckbox from "./TableHeaderCheckbox";

const TableHeader = ({
  columns = [],
  allRows = [],
  selectedRows = [],
  isHaveCheckbox = false,
  isHaveAction = false,
  setSelectedRows,
  orderBy,
  setOrderBy,
}) => {
  const handleRequestSort = (order) => {
    if (orderBy === order) {
      setOrderBy(`-${order}`);
    } else if (orderBy === `-${order}`) {
      setOrderBy("");
    } else {
      setOrderBy(order);
    }
  };

  const getOrderDirection = (order) => {
    return orderBy === order ? "asc" : "desc";
  };

  return (
    <TableHead>
      <TableRow>
        {isHaveCheckbox && (
          <TableHeaderCheckbox
            allRows={allRows}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
        )}

        {columns.map((item) => {
          switch (item.param) {
            case "id":
              return null;

            default:
              return (
                <TableCell key={item.name}>
                  {item.sortable ? (
                    <TableSortLabel
                      sx={{ width: "100%" }}
                      active={
                        orderBy === item.order || orderBy === `-${item.order}`
                      }
                      direction={getOrderDirection(item.order)}
                      onClick={() => handleRequestSort(item.order)}
                    >
                      {item.name}
                    </TableSortLabel>
                  ) : (
                    <>{item.name}</>
                  )}
                </TableCell>
              );
          }
        })}

        {isHaveAction && <TableCell />}
      </TableRow>
    </TableHead>
  );
};

TableHeader.propTypes = {
  columns: PropTypes.array,
  orderBy: PropTypes.string,
  setOrderBy: PropTypes.func,
  allRows: PropTypes.array,
  setSelectedRows: PropTypes.func,
  selectedRows: PropTypes.array,
  isHaveCheckbox: PropTypes.bool,
  isHaveAction: PropTypes.bool,
};

export default TableHeader;
