import React from "react";
import PropTypes from "prop-types";
import { TableCell, TableRow } from "@mui/material";
import { styled } from "@mui/system";

const StyledCell = styled(TableCell)({
  fontWeight: "bold",
});

const summaryMapping = {
  position_size_usd: "sum_position_size_usd",
  r_pnl_usd: "sum_r_pnl_usd",
  u_pnl_usd: "sum_u_pnl_usd",
};

const excludeFromSummary = [
  "stage_name",
  "updated_at",
  "strategy",
  "direction",
  "signal_id",
  "ticker",
  "avg_enter_price",
  "k_exit",
  "k_exit_coefficient",
  "current_price",
  "mark_price",
  "tr_in_atr",
  "inc_vola",
  "atr_i_minus_1_percent",
  "atr_t_percent",
  "execution",
  "status",
];

const PositionTableSummary = ({ summary, columns }) => {
  return (
    <TableRow>
      {columns.map((column, index) => {
        if (index === 0) {
          return (
            <StyledCell colSpan={2} key={column.param}>
              Summary
            </StyledCell>
          );
        }

        if (excludeFromSummary.includes(column.param)) {
          return <StyledCell key={column.param}></StyledCell>;
        }

        if (column.param === "id") {
          return null;
        }

        const summaryKey = summaryMapping[column.param];

        if (["position_size_usd"].includes(column.param)) {
          return (
            <StyledCell key={column.param}>
              {summary[summaryKey]?.toFixed(0) ?? "0"}
            </StyledCell>
          );
        }

        if (["r_pnl_usd", "u_pnl_usd"].includes(column.param)) {
          return (
            <StyledCell key={column.param}>
              {summary[summaryKey]?.toFixed(2) ?? "0"}
            </StyledCell>
          );
        }

        return (
          <StyledCell key={column.param}>
            {summaryKey && summary[summaryKey] !== undefined
              ? summary[summaryKey]
              : ""}
          </StyledCell>
        );
      })}
    </TableRow>
  );
};

PositionTableSummary.propTypes = {
  summary: PropTypes.object,
  columns: PropTypes.array.isRequired,
};

export default PositionTableSummary;
