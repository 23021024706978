import React from "react";
import PropTypes from "prop-types";
import { COLORS } from "../../styles/colors";

const styles = {
  container: {
    marginBottom: 20,
  },
  link: {
    fontSize: 17,
    lineHeight: "20px",
    paddingBottom: 5,
    marginRight: 20,
    textDecoration: "none",
    color: "inherit",
    cursor: "pointer",
  },
  linkActive: {
    borderBottom: `3px solid ${COLORS.base.primary}`,
  },
};

const ClientStagesTabs = ({ current = "settings", onNavigate }) => {
  return (
    <div style={styles.container}>
      <a
        onClick={() => onNavigate("stage")}
        style={{
          ...styles.link,
          ...(current === "stage" ? styles.linkActive : {}),
        }}
      >
        Client Stages
      </a>
      <a
        onClick={() => onNavigate("edit")}
        style={{
          ...styles.link,
          marginRight: 0,
          ...(current === "edit" ? styles.linkActive : {}),
        }}
      >
        Client Details
      </a>
    </div>
  );
};

ClientStagesTabs.propTypes = {
  current: PropTypes.string.isRequired,
  onNavigate: PropTypes.func.isRequired,
};

export default ClientStagesTabs;
