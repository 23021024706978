import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { PAGINATION_LIMIT } from "../../utils/consts";
import { compareObjects } from "../../utils/compare";

export const amdApi = createApi({
  reducerPath: "amdApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_URL,
    prepareHeaders: (headers, { getState }) => {
      headers.set("Content-Type", "application/json");

      const token = getState().auth.token.accessToken;

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAdministrationList: builder.query({
      query: ({
        limit = PAGINATION_LIMIT,
        offset = 0,
        stage = [],
        exchange = "",
        client = [],
        orderBy = "name",
      }) => {
        const exchangeFilter = exchange ? `&exchange=${exchange}` : "";
        const clientFilter = client.length
          ? "&" + client.map((i) => `client_id=${i}`).join("&")
          : "";
        const stageFilter = stage.length
          ? "&" + stage.map((i) => `stage_id=${i}`).join("&")
          : "";

        const order = orderBy ? `&order_by=${orderBy}` : "";

        return `/amd/stages?limit=${limit}&offset=${offset}${stageFilter}${exchangeFilter}${clientFilter}${order}`;
      },
      method: "GET",
      forceRefetch: ({ currentArg, previousArg }) => {
        return !compareObjects(currentArg, previousArg);
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, { arg }) => {
        if (arg.offset == 0) {
          return {
            rows: newItems.rows,
            isNextPage: newItems.isNextPage,
            summary: newItems.summary,
          };
        }
        return {
          rows: [...currentCache.rows, ...newItems.rows],
          summary: newItems.summary,
          isNextPage: newItems.isNextPage,
        };
      },
      transformResponse: (response) => {
        return {
          rows: response.rows,
          summary: response.summary,
          isNextPage: response?.rows.length > 0,
        };
      },
      providesTags: ["AmdStageList"],
    }),
    syncAdministrationList: builder.mutation({
      query: () => ({
        url: "/amd/stages/sync",
        method: "POST",
      }),
    }),
    closeByMarket: builder.mutation({
      query(body) {
        return {
          url: `/apa/signals/close-by-market`,
          method: "POST",
          body: JSON.stringify(body),
        };
      },
    }),
    exitRecovery: builder.mutation({
      query(body) {
        return {
          url: `/apa/signals/k-exit-recovery`,
          method: "POST",
          body: JSON.stringify(body),
        };
      },
    }),
    reducePosition: builder.mutation({
      query(body) {
        return {
          url: `/apa/signals/reduce-positions`,
          method: "POST",
          body: JSON.stringify(body),
        };
      },
    }),
    closeLimit: builder.mutation({
      query(body) {
        return {
          url: `/apa/signals/close-limit`,
          method: "POST",
          body: JSON.stringify(body),
        };
      },
    }),
    closeBestLimit: builder.mutation({
      query(body) {
        return {
          url: `/apa/signals/close-best-limit`,
          method: "POST",
          body: JSON.stringify(body),
        };
      },
    }),
    searchStageByName: builder.query({
      query: ({ name = "" }) => `/amd/stages?limit=5&name=${name}`,
      method: "GET",
      forceRefetch: ({ currentArg, previousArg }) => {
        return currentArg !== previousArg;
      },
    }),
  }),
});

export const {
  useGetAdministrationListQuery,
  useSearchStageByNameQuery,
  useSyncAdministrationListMutation,
  useCloseByMarketMutation,
  useCloseBestLimitMutation,
  useExitRecoveryMutation,
  useReducePositionMutation,
  useCloseLimitMutation,
} = amdApi;
