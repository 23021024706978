import React from "react";
import PropTypes from "prop-types";
import { Button, Grid, Typography } from "@mui/material";
import { ActionModal, InputText } from "../";
import { useReducePositionMutation } from "../../store/services/amd.api";
import { Formik } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  reduce: Yup.number()
    .min(0.01)
    .typeError("Reduce should be a number")
    .required(),
});

const ReducePositionModal = ({ isOpen, setIsOpen, selectedRows }) => {
  const [reducePosition, { isLoading }] = useReducePositionMutation();

  return (
    <ActionModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={"Reduce positions"}
    >
      <>
        <Typography sx={{ mb: 3, fontSize: 14 }}>
          You are going to reduce all the chosen positions. Please enter
          reducing percentage:
        </Typography>

        <Formik
          initialValues={{
            reduce: 5,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            try {
              await reducePosition({
                signal_ids: selectedRows.map((row) => row.id),
                reducing_percent: values.reduce,
              });
              setIsOpen(false);
            } catch (error) {
              console.log(error);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid item xs>
                <InputText
                  label={"Reducing"}
                  name="reduce"
                  placeholder="0"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.reduce}
                  error={errors.reduce && touched.reduce}
                  helperText={errors.reduce && touched.reduce && errors.reduce}
                />
              </Grid>

              <Grid container columnGap={2}>
                <Grid item xs>
                  <Button
                    onClick={() => setIsOpen(false)}
                    fullWidth
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={isLoading || isSubmitting || !isValid}
                  >
                    Confirm
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </>
    </ActionModal>
  );
};

ReducePositionModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  selectedRows: PropTypes.array,
};

export default ReducePositionModal;
