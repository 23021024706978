import React from "react";
import PropTypes, { string } from "prop-types";
import { Card, CardContent, Typography, styled } from "@mui/material";

const LoginContainer = styled(Card)({
  background: "white",
  width: 380,
  borderRadius: 10,
  boxShadow: "0px 0px 6.5px -1px #485A7645",
});

const LoginBody = styled(CardContent)({
  padding: "40px !important",
  textAlign: "center",
});

const Title = styled(Typography)({
  fontSize: 18,
  fontWeight: 700,
  lineHeight: "26px",
  textAlign: "center",
  marginBottom: 20,
});

const LoginBox = ({ title, children }) => {
  return (
    <LoginContainer>
      <LoginBody>
        <Title>{title}</Title>
        {children}
      </LoginBody>
    </LoginContainer>
  );
};

LoginBox.propTypes = {
  title: string,
  children: PropTypes.node,
};

export default LoginBox;
