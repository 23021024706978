import React from "react";
import PropTypes from "prop-types";
import { Box, Grid, Typography } from "@mui/material";
import { styled } from "@mui/system";

const StyledBox = styled(Box)({
  marginBottom: 30,
});

const PageHeader = ({ title, action }) => {
  return (
    <StyledBox>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item xs>
          <Typography variant="h6">{title}</Typography>
        </Grid>
        <Grid item>{action}</Grid>
      </Grid>
    </StyledBox>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string,
  action: PropTypes.node,
};

export default PageHeader;
