import * as Sentry from "@sentry/react";
import { browserTracingIntegration, replayIntegration } from "@sentry/browser";

export const enableSentryIntegration = () => {
  if (process.env.REACT_APP_SENTRY_ENABLED === "true") {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
      integrations: [replayIntegration(), browserTracingIntegration()],
      tracesSampleRate: 1.0,
    });
  }
};
