import React, { useState } from "react";
import PropTypes from "prop-types";
import { useGetClientFilterQuery } from "../store/services/client.api";

import InputMultiselect from "./InputMultiselect";

const ClientFilterInput = ({ getSelected, value }) => {
  const [queryClients, setQueryClients] = useState("");
  const [offset, setOffset] = useState(0);
  const searchByClientResult = useGetClientFilterQuery({
    name: queryClients,
    offset,
  });

  const getQueryByClient = (value) => {
    setOffset(0);
    setQueryClients(value || "");
  };

  const optionsClient = searchByClientResult?.data
    ? searchByClientResult?.data?.client?.map((item) => ({
        label: item.name,
        ...item,
      }))
    : [];

  const handleScroll = (event) => {
    const bottom =
      event.target.scrollHeight - event.target.scrollTop <=
      event.target.clientHeight + 10;

    if (
      bottom &&
      searchByClientResult?.data?.isNextPage &&
      !searchByClientResult.isFetching
    ) {
      setOffset((prevOffset) => prevOffset + 50);
    }
  };

  return (
    <InputMultiselect
      options={optionsClient}
      label={"Clients"}
      value={value}
      getSelected={getSelected}
      handleInputChange={getQueryByClient}
      loading={searchByClientResult.isFetching}
      listboxProps={{
        onScroll: handleScroll,
        style: {
          maxHeight: "550px",
        },
      }}
    />
  );
};

ClientFilterInput.displayName = "ClientFilterInput";

ClientFilterInput.propTypes = {
  value: PropTypes.array,
  getSelected: PropTypes.func,
  handleInputChange: PropTypes.func,
  freeSolo: PropTypes.bool,
  listboxProps: PropTypes.object,
};

export default ClientFilterInput;
