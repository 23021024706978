import React from "react";
import PropTypes from "prop-types";
import { Box, Grid, IconButton, Modal, Typography } from "@mui/material";
import { CloseModalIcon } from "../assets";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  borderRadius: 3,
  boxShadow: 24,
  p: 4,
};

const ActionModal = ({ isOpen, setIsOpen, title, children }) => {
  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      <Box sx={style}>
        <Grid container sx={{ mb: 2 }}>
          <Grid item xs>
            <Typography sx={{ fontWeight: "700", fontSize: 20 }}>
              {title}
            </Typography>
          </Grid>
          <Grid>
            <IconButton onClick={() => setIsOpen(false)}>
              <img src={CloseModalIcon} alt="close" />
            </IconButton>
          </Grid>
        </Grid>
        {children}
      </Box>
    </Modal>
  );
};

ActionModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.node,
};

export default ActionModal;
