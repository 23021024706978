import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import { amdApi } from "./services/amd.api";
import { apaApi } from "./services/apa.api";
import { sssApi } from "./services/sss.api";
import { clientApi } from "./services/client.api";
import { bgtApi } from "./services/bgt.api";
import { fetchBaseQuery } from "@reduxjs/toolkit/query";

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BACKEND_URL,
  prepareHeaders: (headers, { getState }) => {
    headers.set("Content-Type", "application/json");

    const token = getState().auth.token.accessToken;

    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }

    return headers;
  },
});

export const store = configureStore({
  reducer: {
    auth: authReducer,
    [amdApi.reducerPath]: amdApi.reducer,
    [apaApi.reducerPath]: apaApi.reducer,
    [sssApi.reducerPath]: sssApi.reducer,
    [clientApi.reducerPath]: clientApi.reducer,
    [bgtApi.reducerPath]: bgtApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      amdApi.middleware,
      apaApi.middleware,
      sssApi.middleware,
      clientApi.middleware,
      bgtApi.middleware,
    ]),
});
