export const COLORS = {
  base: {
    primary: "#415ff6",
    secondary: "#b5f562",
    tertiary: "#485a76",
  },
  text: {
    primary: "#161616",
    secondary: "#7d899b",
    disabled: "#a5acbc",
  },
  utility: {
    border: "#dce5f0",
    background: "#f5f7fa",
    surface: "#dee0eb",
  },
  icon: {
    default: "#99a3b2",
    selected: "#edf0f4",
    disabled: "#c7d5dd",
  },
  table: {
    default: "#c7d5dd",
    selected: "#f0f3f7",
    header: "#ffffff",
  },
  status: {
    success: '#0F9052',
    warning: '#E3D146',
    danger: '#D11E34'
  }
};
