import React, { useEffect, useState } from "react";
import { Button, Paper, Table, TableContainer } from "@mui/material";
import {
  AdministrationTableBody,
  AdministrationTableFilter,
  Loader,
  Navigation,
  PageHeader,
  TableHeader,
} from "../components";
import createColumnData from "../utils/stages/createColumnData";
import {
  useGetAdministrationListQuery,
  useSyncAdministrationListMutation,
} from "../store/services/amd.api";
import { PAGINATION_LIMIT } from "../utils/consts";

const columns = [
  createColumnData("Stage name", "name", "stage.name", true),
  createColumnData("Nav ($)", "nav", "stage.nav", true),
  createColumnData("Exchange", "exchange", "stage.exchange", true),
  createColumnData("Client", "client", "client.name", true),
  createColumnData("Asset sum", "asset_sum", "stage.asset_sum", true),
  createColumnData(
    "VAR-positions",
    "var_positions",
    "stage.var_positions",
    true,
  ),
  createColumnData("VAR-orders", "var_orders", "stage.var_orders", true),
  createColumnData("Free VAR", "free_var", "stage.free_var", true),
  createColumnData("Imp.Loss ($)", "imp_loss", "stage.imp_loss", true),
  createColumnData("MaxDD (%)", "max_dd", "stage.max_dd", true),
  createColumnData("MaxDDD (%)", "max_ddd", "stage.max_ddd", true),
  createColumnData("ROI-month (%)", "roi_month", "stage.roi_month", true),
  createColumnData(
    "ROI-all time (%)",
    "roi_all_time",
    "stage.roi_all_time",
    true,
  ),
  createColumnData("ID", "id", "stage.id", true),
];

const AdministrationMasterDataPage = () => {
  const paginationLimit = PAGINATION_LIMIT;

  const [offset, setOffset] = useState(0);
  const [orderBy, setOrderBy] = useState("");

  const [filterStage, setFilterStage] = useState([]);
  const [filterClient, setFilterClient] = useState([]);
  const [filterExchange, setFilterExchange] = useState("");

  const [excludedCells, setExcludedCells] = useState(["exchange", "client"]);

  const [syncList, syncStatus] = useSyncAdministrationListMutation();
  const { data, isLoading, isFetching } = useGetAdministrationListQuery({
    paginationLimit,
    offset,
    stage: filterStage.map((i) => i.id),
    client: filterClient.map((i) => i.id),
    exchange: filterExchange,
    orderBy,
  });

  const handleCellExclusion = (excludedCell) => {
    if (excludedCells?.includes(excludedCell)) {
      setExcludedCells(excludedCells.filter((cell) => cell !== excludedCell));
    } else {
      setExcludedCells([...excludedCells, excludedCell]);
    }
  };

  const filteredColumns = columns.filter(
    (item) => !excludedCells.includes(item.param),
  );

  const filteredSummary = Object.fromEntries(
    Object.entries(data?.summary || {}).filter(
      ([key]) => !excludedCells.includes(key.substring(4)),
    ),
  );

  const orderedRows = data?.rows?.map((row) => {
    const orderedRow = {};
    columns.forEach((column) => {
      orderedRow[column.param] = row[column.param];
    });
    return orderedRow;
  });

  const filteredRows = orderedRows?.map((obj) =>
    Object.fromEntries(
      Object.entries(obj).filter(([key]) => {
        return !excludedCells.includes(key);
      }),
    ),
  );

  useEffect(() => {
    const onScroll = () => {
      const scrolledToBottom =
        window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight - 1;
      if (scrolledToBottom && !isFetching) {
        if (data.isNextPage) {
          setOffset((prevOffset) => prevOffset + paginationLimit);
        }
      }
    };

    document.addEventListener("scroll", onScroll);

    return function () {
      document.removeEventListener("scroll", onScroll);
    };
  }, [offset, isFetching]);

  const handleSetFilterStage = (value) => {
    setOffset(0);
    setFilterStage(value);
  };

  const handleSetFilterExchange = (value) => {
    setOffset(0);
    setFilterExchange(value);
  };

  const handleSetFilterClient = (value) => {
    setOffset(0);
    setFilterClient(value);
  };

  const handleSetOrderBy = (value) => {
    setOffset(0);
    setOrderBy(value);
  };

  return (
    <Navigation>
      <PageHeader
        title={"Administration master data (AMD)"}
        action={
          <Button
            variant="contained"
            disabled={syncStatus.isLoading}
            onClick={async () => await syncList()}
          >
            {syncStatus.isLoading ? "Synchronizing..." : "Synchronize"}
          </Button>
        }
      />

      <TableContainer
        container={Paper}
        style={{ width: "calc(100vw - 100px)" }}
      >
        <AdministrationTableFilter
          setCellExclusion={handleCellExclusion}
          excludedCells={excludedCells}
          setFilterStage={handleSetFilterStage}
          setFilterExchange={handleSetFilterExchange}
          setFilterClient={handleSetFilterClient}
          cells={columns}
        />
        <div style={{ overflowX: "auto" }}>
          <Table sx={{ maxWidth: "calc(100vw - 100px)" }}>
            <TableHeader
              columns={filteredColumns}
              orderBy={orderBy}
              setOrderBy={handleSetOrderBy}
            />
            <AdministrationTableBody
              rows={filteredRows}
              summary={filteredSummary}
              columns={filteredColumns}
            />
          </Table>
        </div>
      </TableContainer>

      <Loader isLoading={isLoading || isFetching} />
    </Navigation>
  );
};

export default AdministrationMasterDataPage;
