import React, { useEffect, useState } from "react";
import { Button, Paper, Table, TableContainer } from "@mui/material";
import {
  Navigation,
  PageHeader,
  ClientTableBody,
  Loader,
  ClientCreateModal,
  TableHeader,
} from "../components";
import { useGetClientListQuery } from "../store/services/client.api";
import { PAGINATION_LIMIT } from "../utils/consts";
import { createColumnData } from "../utils/stages";

const columns = [
  createColumnData("Client", "name", "client.name", true),
  createColumnData("Stages Quantity", "stages", "client.stages_count", true),
];

const ClientList = () => {
  const paginationLimit = PAGINATION_LIMIT;

  const [openModal, setOpenModal] = useState(false);
  const [offset, setOffset] = useState(0);
  const [orderBy, setOrderBy] = useState("");

  const { data, isLoading, isFetching } = useGetClientListQuery({
    paginationLimit,
    offset,
    orderBy,
  });

  useEffect(() => {
    const onScroll = () => {
      const scrolledToBottom =
        window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight - 1;
      if (scrolledToBottom && !isFetching) {
        if (data.isNextPage) {
          setOffset((prevOffset) => prevOffset + paginationLimit);
        }
      }
    };

    document.addEventListener("scroll", onScroll);

    return function () {
      document.removeEventListener("scroll", onScroll);
    };
  }, [offset, isFetching]);

  const handleSetRefresh = () => {
    setOffset(0);
    setOrderBy("");
  };

  const handleSetOrderBy = (value) => {
    setOffset(0);
    setOrderBy(value);
  };

  return (
    <Navigation>
      <ClientCreateModal
        isOpen={openModal}
        setIsOpen={setOpenModal}
        setRefresh={handleSetRefresh}
      />
      <PageHeader
        title={"Clients"}
        action={
          <Button onClick={() => setOpenModal(!openModal)} variant="contained">
            Add new client
          </Button>
        }
      />

      <TableContainer container={Paper}>
        <Table>
          <TableHeader
            columns={columns}
            orderBy={orderBy}
            setOrderBy={handleSetOrderBy}
            isHaveAction
          />
          <ClientTableBody rows={data?.rows} />
        </Table>
      </TableContainer>

      <Loader isLoading={isLoading || isFetching} />
    </Navigation>
  );
};

export default ClientList;
