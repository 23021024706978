import React from "react";
import PropTypes from "prop-types";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  InputLabel,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/system";
import { InputText, InputSelect } from "../../";
import { COLORS } from "../../../styles/colors";
import { optionsExchange, optionsPortfolio } from "../../../utils/consts";

const Status = styled(Typography)({
  fontSize: 14,
  marginBottom: 10,
  color: COLORS.text.secondary,
});

const StatusContainer = styled(Grid)({
  display: "flex",
  alignItems: "center",
  marginTop: 10,
});

const Container = styled(Grid)({
  marginTop: "15px",
});

const Label = styled(InputLabel)({
  fontSize: 14,
  marginBottom: "5px",
});

const TradignInformation = ({
  handleChange,
  handleBlur,
  values,
  setFieldValue,
  errors,
  touched,
}) => {
  const getHelperText = (key) => {
    return errors[key] && touched[key] && errors[key];
  };

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        Trading information
      </AccordionSummary>
      <AccordionDetails>
        <Container container columnSpacing={2} columns={4}>
          <StatusContainer item xs={1}>
            <Status>Trading status: {values.trading_status}</Status>
          </StatusContainer>
          <Grid item xs={1}>
            <Label>Exchange</Label>
            <InputSelect
              label={"Exchange"}
              options={optionsExchange}
              value={
                optionsExchange.find((o) => o.name === values.exchange) || {
                  label: "Not selected",
                  name: "not-selected",
                }
              }
              getSelected={(value) => setFieldValue("exchange", value?.name)}
              error={errors.exchange && touched.exchange}
              helperText={getHelperText("exchange")}
              autocompleteParams={{
                getOptionLabel: (option) => option?.label || "",
                isOptionEqualToValue: (option, value) =>
                  option?.name === value?.name,
              }}
            />
          </Grid>
          <Grid item xs={1}>
            <InputText
              label={"Leverage"}
              name="leverage"
              placeholder="0"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values?.leverage || ""}
              error={errors.leverage && touched.leverage}
              helperText={getHelperText("leverage")}
            />
          </Grid>
          <Grid item xs={1}>
            <Label>Portfolio</Label>
            <InputSelect
              label={"Portfolio"}
              options={optionsPortfolio}
              value={optionsPortfolio.find((o) => o.name === values.portfolio)}
              getSelected={(value) => setFieldValue("portfolio", value.name)}
              error={errors.portfolio && touched.portfolio}
              helperText={getHelperText("portfolio")}
              autocompleteParams={{
                getOptionLabel: (option) => option.label || "",
                isOptionEqualToValue: (option, value) =>
                  option.name === value.name,
              }}
            />
          </Grid>
        </Container>
      </AccordionDetails>
    </Accordion>
  );
};

TradignInformation.propTypes = {
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
};

export default TradignInformation;
