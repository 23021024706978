import React from "react";
import PropTypes from "prop-types";
import { Button, Grid } from "@mui/material";
import { ActionModal, InputText } from "../";
import { Formik } from "formik";
import { useCreateClientMutation } from "../../store/services/client.api";
import * as Yup from "yup";

const ClientCreateModal = ({ isOpen, setIsOpen, setRefresh }) => {
  const [addNewClient] = useCreateClientMutation();

  const validationSchema = Yup.object().shape({
    name: Yup.string().min(3).required("Name is required"),
  });

  return (
    <ActionModal isOpen={isOpen} setIsOpen={setIsOpen} title={"Add new client"}>
      <Formik
        initialValues={{ name: "" }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          try {
            await addNewClient(values);
            setIsOpen(false);
            setRefresh();
          } catch (error) {
            console.error(error);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <InputText
              label={"New client name"}
              placeholder={"Client name"}
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              error={errors.name && touched.name}
              helperText={errors.name && touched.name && errors.name}
            />

            <Grid container columnGap={2}>
              <Grid xs item>
                <Button
                  onClick={() => setIsOpen(false)}
                  fullWidth
                  variant="outlined"
                  sx={{ borderWidth: 2 }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid xs item>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={isSubmitting}
                >
                  Add Client
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </ActionModal>
  );
};

ClientCreateModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  setRefresh: PropTypes.func,
};

export default ClientCreateModal;
