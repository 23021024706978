import { EventSource } from "extended-eventsource";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import TooltipModal from "./TooltipModal";
import { isDevMode } from "../utils/dev";

const SSENotification = () => {
  const { token } = useSelector((state) => state.auth);
  const [events, setEvents] = useState([]);

  const eventSourceRef = useRef(null);

  const getEventSourceConnection = () => {
    return new EventSource(`${process.env.REACT_APP_BACKEND_URL}act/sse`, {
      headers: {
        Authorization: `Bearer ${token.accessToken}`,
      },
    });
  };

  const deleteEventById = (eventId) => {
    if (isDevMode()) {
      console.log("Delete event: ", eventId);
    }
    setEvents(events.filter((item) => item.id !== eventId));
  };

  useEffect(() => {
    eventSourceRef.current = getEventSourceConnection();

    eventSourceRef.current.onmessage = (msg) => {
      if (isDevMode()) {
        console.log("Message: ", msg);
      }

      if (msg.data) {
        const data = JSON.parse(msg.data);
        setEvents([...events, data]);
      }
    };

    eventSourceRef.current.onopen = () => {
      if (isDevMode()) {
        console.log("Connection opened");
      }
    };

    eventSourceRef.current.onerror = (error) => {
      if (isDevMode()) {
        console.error("Error occurred: ", error);
      }
      eventSourceRef.current = getEventSourceConnection();
    };

    return () => {
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
        if (isDevMode()) {
          console.log("EventSource closed");
        }
      }
    };
  }, []);

  return (
    <>
      {events.map((event) => (
        <TooltipModal
          key={event.id}
          isOpen={true}
          status={event.status.toLowerCase()}
          title={event.message}
          eventId={event.id}
          deleteEventById={deleteEventById}
        />
      ))}
    </>
  );
};

export default SSENotification;
