import React from "react";
import {
  ControlSettings,
  Navigation,
  ReportSettings,
  StageBreadcrumbs,
  StageInformation,
  TelegramSettings,
  TradingInformation,
} from "../components";
import {
  useGetStagesByIdQuery,
  useUpdateStageByIdMutation,
} from "../store/services/sss.api";
import { Formik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import StageTabs from "../components/stages/StageTabs";
import isValidDomain from "is-valid-domain";

const CRON_REGEX = /^(\*|(\*\/[0-9]+)|([0-5]?\d)) (\*|(\*\/[0-9]+)|([01]?\d|2[0-3])) (\*|(\*\/[0-9]+)|([1-9]|[12]\d|3[01])) (\*|(\*\/[0-9]+)|(1[0-2]|0?[1-9])) (\*|(\*\/[0-9]+)|([0-6]))$/;

const validationSchema = Yup.object().shape({
  name: Yup.string().min(2).required(),
  domain: Yup.string()
    .test(
      "domain",
      "Invalid domain format. Please enter a valid domain name, such as example.com",
      function (value) {
        return isValidDomain(value);
      },
    )
    .required(),
  client_id: Yup.string().required(),
  exchange: Yup.string().required(),
  portfolio: Yup.string().required(),
  leverage: Yup.number()
    .required()
    .lessThan(101, "leverage should not be more than 100"),
  report_schedulers: Yup.array().of(
    Yup.object().shape({
      schedule_type: Yup.string().required("Schedule type is required"),
      schedule_value: Yup.string().when("schedule_type", {
        is: "custom",
        then: (schema) =>
          schema
            .matches(CRON_REGEX, "Invalid cron expression")
            .required("Cron expression is required"),
        otherwise: (schema) =>
          schema.required("Report time is required"),
      }),
    })
  ),
});

const SssEditPage = () => {
  let { id } = useParams();
  const { data, isLoading } = useGetStagesByIdQuery(id, false);
  const [updateStage] = useUpdateStageByIdMutation();

  const onUpdateStage = async (values) => {
    const exclude = [
      "trading_status",
      "system_status",
      "start_date",
      "q_active_strategies",
      "q_days",
      "client",
      "last_activity_at",
      "keycloak_client_id",
      "high_water_mark",
    ];

    if (values.bot_chat_id && !values.bot_token) {
      exclude.push("bot_chat_id");
      exclude.push("bot_token");
    }

    if (values.investor_chat_id && !values.investor_bot_token) {
      exclude.push("investor_chat_id");
      exclude.push("investor_bot_token");
    }

    try {
      let payload = {};
      Object.keys(values).forEach((key) => {
        if (!exclude.includes(key)) {
          payload[key] = values[key];
        }
      });

      await updateStage(payload);
    } catch (error) {
      console.error(error);
    }
  };

  if (isLoading) {
    return (
      <Navigation>
        <StageBreadcrumbs name={"Stage Edit"} />
        Loading ...
      </Navigation>
    );
  }

  return (
    <Navigation>
      <StageBreadcrumbs name={"Stage Settings"} />
      <h2>{data?.name}</h2>
      <StageTabs current="settings" id={id} />

      <Formik
        initialValues={{
          ...data,
          client_id: data?.client?.id,
          report_schedulers: data?.report_schedulers || [],
        }}
        validationSchema={validationSchema}
        onSubmit={onUpdateStage}
      >
        {({ handleSubmit, ...formikProps }) => (
          <form onSubmit={handleSubmit}>
            <StageInformation {...formikProps} />
            <TradingInformation {...formikProps} />
            <TelegramSettings {...formikProps} />
            <ReportSettings {...formikProps} />
            <ControlSettings {...formikProps} />
          </form>
        )}
      </Formik>
    </Navigation>
  );
};

export default SssEditPage;
