import React from "react";
import PropTypes from "prop-types";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "@mui/material";

const NavigationItem = ({ label, icon, open, handleClick }) => (
  <Link sx={{ textDecoration: "none" }} onClick={() => handleClick()}>
    <ListItem disablePadding sx={{ display: "block" }}>
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: open ? "initial" : "center",
          px: 2.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : "auto",
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText
          primary={label}
          sx={{ opacity: open ? 1 : 0, color: "white" }}
        />
      </ListItemButton>
    </ListItem>
  </Link>
);

NavigationItem.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.node,
  open: PropTypes.bool,
  handleClick: PropTypes.func,
};

export default NavigationItem;
