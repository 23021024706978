import React, { useState } from "react";
import { Menu, Button, Divider } from "@mui/material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { styled } from "@mui/system";
import { COLORS } from "../styles/colors";
import ActionDropdownItem from "./ActionDropdownItem";
import PropTypes from "prop-types";

import CloseByMarketModal from "./position/CloseByMarketModal";
import ExitRecoveryModal from "./position/ExitRecoverModal";
import ReducePositionModal from "./position/ReducePositionModal";
import CloseLimitModal from "./position/CloseLimitModal";
import ChangeByDistance from "./position/ChangeByDistance";
import ChangeByTimer from "./position/ChangeByTimer";
import CloseByBestLimitModal from "./position/CloseByBestLimitModal";

const StyledButton = styled(Button)({
  paddingLeft: 10,
  paddingRight: 10,
  borderColor: COLORS.utility.border,
  borderRadius: 5,
});

const ActionDropdown = ({ selectedRows }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [openCloseByBestLimitModal, setOpenCloseByBestLimitModal] = useState(false);
  const [openCloseByMarketModal, setOpenCloseByMarketModal] = useState(false);
  const [openExitRecoverModal, setOpenExitRecoverytModal] = useState(false);
  const [openReducePositionModal, setOpenReducePositionModal] = useState(false);
  const [openCloseLimitModal, setOpenCloseLimitModal] = useState(false);
  const [openChangeByTimer, setOpenChangeByTimer] = useState(false);
  const [openChangeByDistance, setOpenChangeByDistance] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const areAllValuesEqual = (array, key) => {
    const firstValue = array[0][key];
    return array.every((item) => item[key] === firstValue);
  };

  const showChangeByTimerButton = areAllValuesEqual(
    selectedRows,
    "skip_check_by_timer",
  );
  const showChangeByDistanceButton = areAllValuesEqual(
    selectedRows,
    "skip_check_by_distance",
  );

  const skipCheckByTimerValue =
    selectedRows[0]["skip_check_by_timer"] === true ? true : false;
  const skipCheckByDistanceValue =
    selectedRows[0]["skip_check_by_distance"] === true ? true : false;

  return (
    <div>
      <StyledButton
        variant="outlined"
        onClick={handleClick}
        endIcon={<KeyboardArrowDownRoundedIcon />}
      >
        Actions
        <Divider orientation="vertical" sx={{ ml: 1 }} />
      </StyledButton>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <ActionDropdownItem
          item={"Close by Best Limit"}
          onClick={() => setOpenCloseByBestLimitModal(true)}
        />
        <ActionDropdownItem
          item={"Reduce position"}
          onClick={() => setOpenReducePositionModal(true)}
        />
        <ActionDropdownItem
          item={"Close by market"}
          onClick={() => setOpenCloseByMarketModal(true)}
        />
        <ActionDropdownItem
          item={"Close by limit"}
          onClick={() => setOpenCloseLimitModal(true)}
        />
        <ActionDropdownItem
          item={"Exit recovery"}
          onClick={() => setOpenExitRecoverytModal(true)}
        />
        {showChangeByTimerButton && (
          <ActionDropdownItem
            item={
              skipCheckByTimerValue
                ? "Disable “change k-exit by timer“"
                : "Enable “change k-exit by timer“"
            }
            onClick={() => setOpenChangeByTimer(true)}
          />
        )}
        {showChangeByDistanceButton && (
          <ActionDropdownItem
            item={
              skipCheckByDistanceValue
                ? "Disable “change k-exit by distance“"
                : "Enable “change k-exit by distance“"
            }
            onClick={() => setOpenChangeByDistance(true)}
          />
        )}
      </Menu>

      <CloseByBestLimitModal
        selectedRows={selectedRows}
        isOpen={openCloseByBestLimitModal}
        setIsOpen={setOpenCloseByBestLimitModal}
      />
      <CloseByMarketModal
        selectedRows={selectedRows}
        isOpen={openCloseByMarketModal}
        setIsOpen={setOpenCloseByMarketModal}
      />
      <ExitRecoveryModal
        selectedRows={selectedRows}
        isOpen={openExitRecoverModal}
        setIsOpen={setOpenExitRecoverytModal}
      />
      <ReducePositionModal
        selectedRows={selectedRows}
        isOpen={openReducePositionModal}
        setIsOpen={setOpenReducePositionModal}
      />
      <CloseLimitModal
        selectedRows={selectedRows}
        isOpen={openCloseLimitModal}
        setIsOpen={setOpenCloseLimitModal}
      />
      <ChangeByDistance
        state={skipCheckByDistanceValue}
        selectedRows={selectedRows}
        isOpen={openChangeByDistance}
        setIsOpen={setOpenChangeByDistance}
      />
      <ChangeByTimer
        state={skipCheckByTimerValue}
        selectedRows={selectedRows}
        isOpen={openChangeByTimer}
        setIsOpen={setOpenChangeByTimer}
      />
    </div>
  );
};

ActionDropdown.propTypes = {
  selectedRows: PropTypes.array,
};

export default ActionDropdown;
