import React from "react";
import PropTypes from "prop-types";
import { Checkbox, TableBody, TableCell, TableRow } from "@mui/material";
import { format } from "date-fns";
import { COLORS } from "../../styles/colors";
import PositionTableSummary from "./PositionTableSummary";
import { Link, useNavigate } from "react-router-dom";

const checkboxStyle = {
  borderRight: `1px solid ${COLORS.utility.border}`,
  width: 50,
  padding: 0,
  textAlign: "center",
};

const PositionTableBody = ({
  rows = [],
  selectedRows,
  onSelectRow,
  summary = {},
  columns = [],
}) => {
  const navigate = useNavigate();

  const onNavigateStage = (row) =>
    navigate(`/stages-system/edit/${row.stage_name}/${row.id}`);

  function formatNumber(num) {
    if (num === null || num === undefined || num === "") {
      return "-";
    } else if (num < 10 && num > -10) {
      return num.toFixed(2);
    } else {
      return Math.round(num);
    }
  }

  return (
    <TableBody>
      {rows.map((row) => (
        <TableRow key={row.id}>
          <TableCell padding="checkbox" sx={checkboxStyle}>
            <Checkbox
              checked={!!selectedRows.find((item) => item.id === row.id)}
              onClick={() => onSelectRow(row)}
            />
          </TableCell>
          {Object.entries(row).map(([key, value]) => {
            switch (key) {
              case "id":
                return null;

              case "name":
                return (
                  <TableCell key={key}>
                    <Link
                      sx={{ cursor: "pointer" }}
                      onClick={() => onNavigateStage(row)}
                    >
                      {value}
                    </Link>
                  </TableCell>
                );

              case "created_at":
                return (
                  <TableCell key={key}>
                    {format(value, "dd.MM.yy HH:mm:ss") ?? "-"}
                  </TableCell>
                );

              case "position_size_usd":
                return (
                  <TableCell key={key}>{value?.toFixed(0) ?? "0"}</TableCell>
                );

              case "r_pnl_usd":
              case "u_pnl_usd":
                return <TableCell key={key}>{formatNumber(value)}</TableCell>;

              case "inc_vola":
              case "atr_i_minus_1_percent":
              case "atr_t_percent":
                return (
                  <TableCell key={key}>{value?.toFixed(2) ?? "0"}</TableCell>
                );

              case "skip_check_by_timer":
              case "skip_check_by_distance":
                return <TableCell key={key}>{value ? "+" : "–"}</TableCell>;

              default:
                return <TableCell key={key}>{value ?? "-"}</TableCell>;
            }
          })}
        </TableRow>
      ))}

      <PositionTableSummary summary={summary} columns={columns} />
    </TableBody>
  );
};

PositionTableBody.propTypes = {
  rows: PropTypes.array,
  onSelectRow: PropTypes.func,
  selectedRows: PropTypes.array,
  summary: PropTypes.object,
  columns: PropTypes.array,
};

export default PositionTableBody;
