import React from "react";
import PropTypes from "prop-types";
import "../styles/base.scss";
import { COLORS } from "../styles/colors";

const STATUS_MAP = {
  active: { text: "Active", color: COLORS.status.success },
  inactive: { text: "Inactive", color: "#DA6B2C" },
  stopping: { text: "Stopping", color: COLORS.status.danger },
};

const TradingStatus = ({ status, disabled = false }) => {
  const { text, color } = STATUS_MAP[status] || STATUS_MAP.stopping;

  return (
    <span className="trading-status" style={{ background: disabled ? '#F7F7F7' : color + '20', color: disabled ? COLORS.text.disabled : color }}>{text}</span>
  );
};

TradingStatus.propTypes = {
  status: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default TradingStatus;