import React from "react";
import PropTypes from "prop-types";
import { Box, Grid } from "@mui/material";
import { styled } from "@mui/system";
import { COLORS } from "../../styles/colors";

const StyledBox = styled(Box)({
  border: `1px solid ${COLORS.utility.border}`,
  borderBottom: 0,
  padding: 15,
  backgroundColor: "white",
  borderTopLeftRadius: 7,
  borderTopRightRadius: 7,
});

const TableFilter = ({ children, style }) => {
  return (
    <StyledBox style={style}>
      <Grid container spacing={2}>
        {children}
      </Grid>
    </StyledBox>
  );
};

TableFilter.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
};

export default TableFilter;
