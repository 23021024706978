import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { PAGINATION_LIMIT } from "../../utils/consts";
import { compareObjects } from "../../utils/compare";

export const sssApi = createApi({
  reducerPath: "sssApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_URL,
    prepareHeaders: (headers, { getState }) => {
      headers.set("Content-Type", "application/json");

      const token = getState().auth.token.accessToken;

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getStagesList: builder.query({
      query: ({
        limit = PAGINATION_LIMIT,
        offset = 0,
        stage = [],
        exchange = "",
        trading = "",
        client = [],
        orderBy = "name",
      }) => {
        const exchangeFilter = exchange ? `&exchange=${exchange}` : "";
        const tradingFilter = trading ? `&trading_status=${trading}` : "";
        const clientFilter = client.length
          ? "&" + client.map((i) => `client_id=${i}`).join("&")
          : "";
        const stageFilter = stage.length
          ? "&" + stage.map((i) => `stage_id=${i}`).join("&")
          : "";
        const order = orderBy ? `&order_by=${orderBy}` : "";

        return `/sss/stages?limit=${limit}&offset=${offset}${stageFilter}${exchangeFilter}${tradingFilter}${clientFilter}${order}`;
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        return !compareObjects(currentArg, previousArg);
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, { arg }) => {
        if (arg.offset == 0) {
          return {
            rows: newItems.rows,
            isNextPage: newItems.isNextPage,
          };
        }
        return {
          rows: [...currentCache.rows, ...newItems.rows],
          isNextPage: newItems.isNextPage,
        };
      },
      transformResponse: (response) => {
        return {
          rows: response,
          isNextPage: response?.length > 0,
        };
      },
      method: "GET",
      providesTags: ["StageList"],
    }),
    getStagesById: builder.query({
      query: (id) => `/sss/stages/${id}`,
      method: "GET",
      providesTags: ["Stage"],
    }),
    getStageHistoryById: builder.query({
      query: ({
        id,
        limit = PAGINATION_LIMIT,
        offset = 0,
        orderBy,
        startDate,
        endDate,
      }) => {
        const orderByFilter = orderBy ? `&order_by=${orderBy}` : "";
        const startDateFilter = startDate ? `&range_from=${startDate}` : "";
        const endDateFilter = endDate ? `&range_to=${endDate}` : "";

        return `/sss/stages/${id}/transaction-history?limit=${limit}&offset=${offset}${orderByFilter}${startDateFilter}${endDateFilter}`;
      },
      method: "GET",
      forceRefetch: ({ currentArg, previousArg }) => {
        return !compareObjects(currentArg, previousArg);
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, { arg }) => {
        if (arg.offset == 0) {
          return {
            rows: newItems.rows,
            isNextPage: newItems.isNextPage,
          };
        }
        return {
          rows: [...currentCache.rows, ...newItems.rows],
          isNextPage: newItems.isNextPage,
        };
      },
      transformResponse: (response) => {
        return {
          rows: response,
          isNextPage: response?.length > 0,
        };
      },
      providesTags: ["TransactionHistoryList"],
    }),
    bulkUpdateStageStatus: builder.mutation({
      query(body) {
        return {
          url: `/sss/stages/change-status`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["StageList"],
    }),
    createStage: builder.mutation({
      query(body) {
        return {
          url: "/sss/stages",
          method: "POST",
          body: JSON.stringify(body),
        };
      },
      invalidatesTags: ["StageList"],
    }),
    deleteStageById: builder.mutation({
      query(body) {
        return {
          url: `/sss/stages/${body}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["StageList"],
    }),
    updateStageById: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `/sss/stages/${id}`,
          method: "PUT",
          body: JSON.stringify(body),
        };
      },
      invalidatesTags: ["Stage"],
    }),
    checkConnectionStatus: builder.mutation({
      query({ id }) {
        return {
          url: `/sss/stages/${id}/check-and-sync`,
          method: "POST",
        };
      },
      invalidatesTags: ["StageList"],
    }),
    updateExchangeApiKey: builder.mutation({
      query(body) {
        return {
          url: `/sss/stages/${body.id}/exchange-api-keys`,
          method: "PATCH",
          body,
        };
      },
      invalidatesTags: ["Stage"],
    }),
    searchStageByName: builder.query({
      query: ({ name = "" }) => `/sss/stages?limit=5&name=${name}`,
      method: "GET",
      forceRefetch: ({ currentArg, previousArg }) => {
        return currentArg !== previousArg;
      },
    }),
  }),
});

export const {
  useGetStagesListQuery,
  useGetStagesByIdQuery,
  useGetStageHistoryByIdQuery,
  useCreateStageMutation,
  useDeleteStageByIdMutation,
  useUpdateStageByIdMutation,
  useCheckConnectionStatusMutation,
  useSearchStageByNameQuery,
  useBulkUpdateStageStatusMutation,
  useUpdateExchangeApiKeyMutation,
} = sssApi;
