const getSystemStatus = (status) => {
  switch (status) {
    case "not_connected":
      return "Not Connected";

    case "not_configured":
      return "Not Configured";

    default:
      return "Configured";
  }
};

export default getSystemStatus;
