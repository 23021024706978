import React from "react";
import PropTypes from "prop-types";
import { TableCell, TableRow } from "@mui/material";
import { styled } from "@mui/system";

const StyledCell = styled(TableCell)({
  fontWeight: "bold",
});

const summaryMapping = {
  nav: "sum_nav",
  asset_sum: "sum_asset_sum",
  var_positions: "sum_var_positions",
  var_orders: "sum_var_orders",
  imp_loss: "sum_imp_loss",
  max_dd: "avg_max_dd",
  max_ddd: "avg_max_ddd",
  roi_month: "avg_roi_month",
  roi_all_time: "avg_roi_all_time",
};

const excludeFromSummary = ["name", "exchange", "client", "free_var"];
const intSummaryRows = [
  "nav",
  "asset_sum",
  "var_positions",
  "var_orders",
  "imp_loss",
];
const floatSummaryRows = ["max_dd", "max_ddd", "roi_month", "roi_all_time"];

const AdministrationTableSummary = ({ summary, columns }) => {
  return (
    <TableRow>
      {columns.map((column, index) => {
        if (index === 0) {
          return <StyledCell key={column.param}>Summary</StyledCell>;
        }

        if (excludeFromSummary.includes(column.param)) {
          return <StyledCell key={column.param}></StyledCell>;
        }

        if (column.param === "id") return null;

        const summaryKey = summaryMapping[column.param];

        if (intSummaryRows.includes(column.param)) {
          return (
            <StyledCell key={column.param}>
              {summary[summaryKey]?.toFixed(0) ?? "0"}
            </StyledCell>
          );
        }

        if (floatSummaryRows.includes(column.param)) {
          return (
            <StyledCell key={column.param}>
              {summary[summaryKey]?.toFixed(2) ?? "0"}
            </StyledCell>
          );
        }

        return (
          <StyledCell key={column.param}>
            {summary[summaryKey] ?? "0"}
          </StyledCell>
        );
      })}
    </TableRow>
  );
};

AdministrationTableSummary.propTypes = {
  summary: PropTypes.object,
  columns: PropTypes.array.isRequired,
};

export default AdministrationTableSummary;
