import React from "react";
import PropTypes from "prop-types";
import { TableBody, TableCell, TableRow, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import getSystemStatus from "../../utils/stages/getSystemStatus";

const ClientStagesTableBody = ({ rows = [] }) => {
  const navigate = useNavigate();

  const onNavigateStage = (row) => navigate(`/stages-system/edit/${row.id}`);

  const tradingStatusCell = (status) => {
    switch (status) {
      case "active":
        return "#0F9052";

      case "inactive":
        return "#D11E34";

      default:
        return "#B3804A";
    }
  };

  return (
    <TableBody>
      {rows.map((row) => (
        <TableRow key={row.name}>
          {Object.entries(row).map(([key, value]) => {
            switch (key) {
              case "id":
                return null;

              case "name":
                return (
                  <TableCell key={key}>
                    <Link
                      sx={{ cursor: "pointer" }}
                      onClick={() => onNavigateStage(row)}
                    >
                      {value}
                    </Link>
                  </TableCell>
                );

              case "system_status":
                return (
                  <TableCell key={key}>{getSystemStatus(value)}</TableCell>
                );

              case "trading_status":
                return (
                  <TableCell
                    key={key}
                    sx={{
                      color: tradingStatusCell(row.trading_status),
                      textTransform: "capitalize",
                    }}
                  >
                    {value}
                  </TableCell>
                );

              default:
                return <TableCell key={key}>{value ?? "-"}</TableCell>;
            }
          })}
        </TableRow>
      ))}
    </TableBody>
  );
};

ClientStagesTableBody.propTypes = {
  rows: PropTypes.array,
};

export default ClientStagesTableBody;
