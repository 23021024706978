import React from "react";
import PropTypes from "prop-types";
import { Button, Grid, Typography } from "@mui/material";
import { ActionModal } from "../";
import { useExitRecoveryMutation } from "../../store/services/amd.api";

const ExitRecoveryModal = ({ isOpen, setIsOpen, selectedRows }) => {
  const [exitRecovery, { isLoading }] = useExitRecoveryMutation();

  const handleCloseAction = async () => {
    try {
      await exitRecovery({ signal_ids: selectedRows.map((row) => row.id) });
      setIsOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ActionModal isOpen={isOpen} setIsOpen={setIsOpen} title={"kE.Recovery"}>
      <>
        <Typography sx={{ mb: 3, fontSize: 14 }}>
          You are going to apply kExit Recovery (place exit order) for all the
          chosen signals
        </Typography>

        <Grid container columnGap={2}>
          <Grid item xs>
            <Button
              onClick={() => setIsOpen(false)}
              fullWidth
              variant="outlined"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs>
            <Button
              onClick={handleCloseAction}
              fullWidth
              variant="contained"
              disabled={isLoading}
            >
              Confirm
            </Button>
          </Grid>
        </Grid>
      </>
    </ActionModal>
  );
};

ExitRecoveryModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  selectedRows: PropTypes.array,
};

export default ExitRecoveryModal;
