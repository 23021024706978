import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Divider } from "@mui/material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import { styled } from "@mui/system";
import { COLORS } from "../styles/colors";

const StyledButton = styled(Button)({
  paddingLeft: 10,
  paddingRight: 10,
  borderColor: COLORS.utility.border,
  borderRadius: 5,
});

const FilterButton = ({ icon, onClick }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
    onClick();
  };

  return (
    <div>
      <StyledButton
        variant="outlined"
        onClick={handleClick}
        endIcon={
          isOpen ? (
            <KeyboardArrowUpRoundedIcon />
          ) : (
            <KeyboardArrowDownRoundedIcon />
          )
        }
        startIcon={icon}
      >
        <Divider orientation="vertical" />
      </StyledButton>
    </div>
  );
};

FilterButton.propTypes = {
  icon: PropTypes.node,
  options: PropTypes.array,
  onClick: PropTypes.func,
};

export default FilterButton;
