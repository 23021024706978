import React from "react";
import PropTypes from "prop-types";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { InputText } from "..";
import { Formik } from "formik";
import { useUpdateClientByIdMutation } from "../../store/services/client.api";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

const ClientEditPage = (client) => {
  const [updateClientById] = useUpdateClientByIdMutation();
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    name: Yup.string().min(3).required("Name is required"),
  });

  return (
    <Formik
      initialValues={client}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        try {
          await updateClientById(values);
          navigate(`/clients/stages/${values.id}`);
        } catch (error) {
          console.error(error);
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              Client Name
            </AccordionSummary>
            <AccordionDetails>
              <Grid container columnGap={2}>
                <Grid item xs={3}>
                  <InputText
                    label={"Client name"}
                    placeholder={"Client name"}
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    error={errors.name && touched.name}
                    helperText={errors.name && touched.name && errors.name}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Grid container columnGap={2}>
            <Grid item xs />
            <Grid item>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={isSubmitting}
              >
                Save changes
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

ClientEditPage.propTypes = {
  client: PropTypes.object,
};

export default ClientEditPage;
