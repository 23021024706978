import React from "react";
import PropTypes from "prop-types";
import {
  Autocomplete,
  TextField,
  FormControl,
  InputLabel,
  Checkbox,
} from "@mui/material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import styled from "@emotion/styled";
import { COLORS } from "../styles/colors";
import { LoaderIcon } from "../assets";
import "../styles/base.scss";

const icon = <CheckBoxOutlineBlankIcon />;
const checkedIcon = <CheckBoxIcon />;

const Label = styled(InputLabel)({
  position: "relative",
  fontSize: 14,
  textAlign: "left",
  transform: "none",
  marginBottom: 5,
});

const InputMultiselect = ({
  options,
  label,
  inputLabel,
  getSelected,
  value,
  handleInputChange,
  freeSolo = false,
  loading = false,
  listboxProps,
  ...otherProps
}) => {
  return (
    <FormControl fullWidth>
      {inputLabel ? <Label>{inputLabel}</Label> : null}
      <Autocomplete
        multiple
        disableCloseOnSelect
        loading={loading}
        style={{ minWidth: 300 }}
        limitTags={2}
        size="small"
        freeSolo={freeSolo}
        options={options}
        value={value}
        onChange={(event, newValue) => getSelected(newValue)}
        onInputChange={(event, newInputValue) =>
          handleInputChange(newInputValue)
        }
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        popupIcon={
          <KeyboardArrowDownRoundedIcon htmlColor={COLORS.base.primary} />
        }
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.label}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            {...otherProps}
            variant="outlined"
            placeholder={label}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <img
                      src={LoaderIcon}
                      alt="Loading"
                      width={14}
                      className="loading"
                    />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        ListboxProps={listboxProps}
      />
    </FormControl>
  );
};

InputMultiselect.propTypes = {
  loading: PropTypes.bool,
  inputLabel: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.array,
  getSelected: PropTypes.func,
  handleInputChange: PropTypes.func,
  freeSolo: PropTypes.bool,
  listboxProps: PropTypes.object,
};

export default InputMultiselect;
