import React from "react";
import PropTypes from "prop-types";
import { MenuItem, Checkbox, ListItemText } from "@mui/material";
import { styled } from "@mui/system";

const StyledMenuItem = styled(MenuItem)({
  paddingLeft: 0,
});

const FilterDropdownItem = ({ isFirst, item, getSelected, isSelected }) => {
  return (
    <StyledMenuItem onClick={getSelected} disabled={isFirst}>
      <Checkbox checked={isSelected} />
      <ListItemText primary={item} disableTypography={isFirst} />
    </StyledMenuItem>
  );
};

FilterDropdownItem.propTypes = {
  isFirst: PropTypes.bool,
  item: PropTypes.string,
  getSelected: PropTypes.func,
  isSelected: PropTypes.bool,
};

export default FilterDropdownItem;
