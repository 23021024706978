import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Grid } from "@mui/material";
import StageApiModal from "../StageApiModal";

const ControlSettings = ({ id, isSubmitting, isValid }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Grid container sx={{ mt: 2 }}>
        <Grid item xs>
          <Button variant="outlined" onClick={() => setModalOpen(true)}>
            Upload Api Keys
          </Button>
        </Grid>
        <Grid>
          <Button type="submit" variant="contained" disabled={isSubmitting || !isValid}>
            Save changes
          </Button>
        </Grid>
      </Grid>

      <StageApiModal id={id} isOpen={isModalOpen} setIsOpen={setModalOpen} />
    </>
  );
};

ControlSettings.propTypes = {
  id: PropTypes.string,
  isValid: PropTypes.bool,
  isSubmitting: PropTypes.bool,
};

export default ControlSettings;
