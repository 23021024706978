import React from "react";
import { TextField, InputLabel, FormControl } from "@mui/material";
import PropTypes, { string } from "prop-types";
import styled from "@emotion/styled";

const Label = styled(InputLabel)({
  position: "relative",
  fontSize: 14,
  textAlign: "left",
  transform: "none",
  marginBottom: 5,
});

const Input = styled(TextField)({
  marginTop: 0,
  marginBottom: 20,
});

const InputText = ({ label, ...otherProps }) => {
  return (
    <FormControl fullWidth>
      {label ? <Label>{label}</Label> : null}
      <Input {...otherProps} />
    </FormControl>
  );
};

InputText.propTypes = {
  label: string,
  children: PropTypes.node,
};

export default InputText;
