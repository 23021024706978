import React from "react";
import PropTypes from "prop-types";
import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import { format } from "date-fns";

const decodeUnicode = (str) => {
  return str.replace(/\\u[\dA-F]{4}/gi, (match) =>
    String.fromCharCode(parseInt(match.replace(/\\u/g, ""), 16)),
  );
};

const statusColors = {
  CONFIRMED: "#0F9052",
  PENDING: "#6444A2",
  FAILED: "#D11E34",
};

const HistoryTableBody = ({ rows = [] }) => {
  if (rows.length === 0) {
    return (
      <TableBody>
        <TableRow>
          <TableCell colSpan={8}>
            <Stack sx={{ width: "100%", textAlign: "center" }} spacing={2}>
              No data yet
            </Stack>
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  return (
    <TableBody>
      {rows.map((row) => (
        <TableRow key={row.name}>
          {Object.entries(row).map(([key, value]) => {
            if (key === "date_time") {
              return (
                <TableCell key={key}>
                  {format(new Date(value), "dd.MM.yy HH:mm:ss") ?? "-"}
                </TableCell>
              );
            }

            if (key === "status") {
              const statusColor = statusColors[value];
              return (
                <TableCell key={key} style={{ color: statusColor }}>
                  {value ?? "-"}
                </TableCell>
              );
            }

            const decodedValue =
              value === null
                ? "-"
                : typeof value === "string"
                  ? decodeUnicode(value)
                  : value;

            return <TableCell key={key}>{decodedValue}</TableCell>;
          })}
        </TableRow>
      ))}
    </TableBody>
  );
};

HistoryTableBody.propTypes = {
  rows: PropTypes.array,
};

export default HistoryTableBody;
