import React from "react";
import PropTypes from "prop-types";
import { COLORS } from "../../styles/colors";
import { useNavigate } from "react-router-dom";

const styles = {
  container: {
    marginBottom: 20,
  },
  link: {
    fontSize: 17,
    lineHeight: "20px",
    paddingBottom: 5,
    marginRight: 20,
    textDecoration: "none",
    color: "inherit",
    cursor: "pointer",
  },
  linkActive: {
    borderBottom: `3px solid ${COLORS.base.primary}`,
  },
};

const StageTabs = ({ current = "settings", id }) => {
  const navigate = useNavigate();

  const onNavigate = (link) => {
    return navigate(`/stages-system/${link}/${id}`);
  };

  return (
    <div style={styles.container}>
      <a
        onClick={() => onNavigate("edit")}
        style={{
          ...styles.link,
          ...(current === "settings" ? styles.linkActive : {}),
        }}
      >
        Stage Settings
      </a>
      <a
        onClick={() => onNavigate("history")}
        style={{
          ...styles.link,
          marginRight: 0,
          ...(current === "history" ? styles.linkActive : {}),
        }}
      >
        Transactions History
      </a>
    </div>
  );
};

StageTabs.propTypes = {
  current: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default StageTabs;
