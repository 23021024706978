import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ActionModal } from "../";
import { useBulkUpdateStageStatusMutation } from "../../store/services/sss.api";
import { TradingStatus } from "../../utils/consts";
import {
  getTradingStatusActionText,
  getTradingStatusColor,
} from "../../utils/strings";

const StageActivateModal = ({ isOpen, setIsOpen, stage }) => {
  const [updateStageStatus, { isLoading }] = useBulkUpdateStageStatusMutation();

  const onChangeStatus = async ({ id, trading_status }) => {
    const status =
      trading_status === TradingStatus.ACTIVE
        ? TradingStatus.STOPPING
        : TradingStatus.STARTING;

    try {
      await updateStageStatus({
        stage_id: id,
        new_trading_status: status,
      });

      setIsOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ActionModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={`${getTradingStatusActionText(stage?.trading_status)} stage`}
    >
      <>
        <Typography sx={{ mb: 3, fontSize: 14 }}>
          {`You are going to ${getTradingStatusActionText(
            stage?.trading_status,
          ).toLowerCase()} stage:`}
        </Typography>

        <Table sx={{ mb: 3 }}>
          <TableHead>
            <TableRow>
              <TableCell>Stage name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{stage?.name}</TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <Grid container columnGap={2}>
          <Grid item xs>
            <Button
              onClick={() => setIsOpen(false)}
              fullWidth
              variant="outlined"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs>
            <Button
              onClick={() => onChangeStatus(stage)}
              fullWidth
              variant="contained"
              color={getTradingStatusColor(stage?.trading_status)}
              disabled={isLoading}
            >
              {getTradingStatusActionText(stage?.trading_status)}
            </Button>
          </Grid>
        </Grid>
      </>
    </ActionModal>
  );
};

StageActivateModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  stage: PropTypes.object,
};

export default StageActivateModal;
