import React from "react";
import PropTypes from "prop-types";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InputText from "../../InputText";

const TelegramSettings = ({ handleChange, handleBlur, values }) => {
  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        Telegram settings
      </AccordionSummary>
      <AccordionDetails>
        <Grid container columnSpacing={2} columns={4}>
          <Grid item xs={1}>
            <InputText
              label={"Chat ID"}
              name="bot_chat_id"
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!values?.bot_token}
              value={values?.bot_chat_id || ""}
            />
          </Grid>
          <Grid item xs={1}>
            <InputText
              label={"Bot API token"}
              name="bot_token"
              onChange={handleChange}
              placeholder={"******************************"}
              onBlur={handleBlur}
              value={values?.bot_token || ""}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

TelegramSettings.propTypes = {
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  values: PropTypes.object,
};

export default TelegramSettings;
