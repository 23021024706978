import React from "react";
import PropTypes from "prop-types";
import { Menu, Button, Divider } from "@mui/material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { styled } from "@mui/system";
import { COLORS } from "../styles/colors";
import FilterDropdownItem from "./FilterDropdownItem";

const StyledButton = styled(Button)({
  paddingLeft: 10,
  paddingRight: 10,
  borderColor: COLORS.utility.border,
  borderRadius: 5,
});

const FilterDropdown = ({ icon, options, getSelected, excludedCells = [] }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <StyledButton
        variant="outlined"
        onClick={handleClick}
        endIcon={<KeyboardArrowDownRoundedIcon />}
        startIcon={icon}
      >
        <Divider orientation="vertical" />
      </StyledButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {options.map((item, index) => (
          <FilterDropdownItem
            isFirst={index === 0}
            key={item.param}
            item={item.name}
            isSelected={!excludedCells?.includes(item.param)}
            getSelected={() => getSelected(item.param)}
          />
        ))}
      </Menu>
    </div>
  );
};

FilterDropdown.propTypes = {
  icon: PropTypes.node,
  options: PropTypes.array,
  getSelected: PropTypes.func,
  excludedCells: PropTypes.array,
};

export default FilterDropdown;
