import PropTypes from "prop-types";
import React, {
  useEffect,
  useState,
  createContext,
  useContext,
  useRef,
} from "react";
import Keycloak from "keycloak-js";
import { useDispatch } from "react-redux";
import { setToken } from "./store/slices/authSlice";

const KeycloakContext = createContext();
export const useKeycloak = () => useContext(KeycloakContext);

const initOptions = {
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT,
};

export const KeycloakProvider = ({ children }) => {
  const [keycloak, setKeycloak] = useState(null);
  const isInitiate = useRef(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isInitiate.current) return;

    isInitiate.current = true;

    const initKeycloak = async () => {
      const keycloakInstance = new Keycloak(initOptions);

      try {
        const isAuthenticated = await keycloakInstance.init({
          onLoad: "login-required",
          redirectUri: window.location.href,
        });

        if (isAuthenticated) {
          keycloakInstance.loadUserInfo().then(() => {
            dispatch(
              setToken({
                accessToken: keycloakInstance.token,
                refreshToken: keycloakInstance.refreshToken,
                idToken: keycloakInstance.idToken,
              }),
            );
          });
        }

        setKeycloak(keycloakInstance);

        // Обработка обновления токена
        const refreshToken = async () => {
          console.log("token refreshed");
          try {
            const refreshed = await keycloakInstance.updateToken(30);
            if (refreshed) {
              dispatch(
                setToken({
                  accessToken: keycloakInstance.token,
                  refreshToken: keycloakInstance.refreshToken,
                  idToken: keycloakInstance.idToken,
                }),
              );
            }
          } catch (error) {
            console.error("Failed to refresh token:", error);
          }
        };

        const tokenUpdateInterval = setInterval(refreshToken, 30000);

        return () => clearInterval(tokenUpdateInterval);
      } catch (error) {
        console.error("Keycloak initialization failed:", error);
      }
    };

    initKeycloak();
  }, []);

  if (!keycloak) {
    return <div>Loading...</div>;
  }

  return (
    <KeycloakContext.Provider value={keycloak}>
      {children}
    </KeycloakContext.Provider>
  );
};

KeycloakProvider.propTypes = {
  children: PropTypes.node,
};
