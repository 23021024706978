import React from "react";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs, Link } from "@mui/material";
import { styled } from "@mui/system";
import PropTypes from "prop-types";

const StyledBreadcrumbs = styled(Breadcrumbs)({
  position: "relative",
  cursor: "pointer",
});

const ClientStagesBreadcrumbs = ({ name }) => {
  const navigate = useNavigate();

  return (
    <StyledBreadcrumbs>
      <Link
        underline="hover"
        color="text.secondary"
        onClick={() => navigate("/clients")}
      >
        Client
      </Link>
      <Link underline="none" color="text.primary">
        {name}
      </Link>
    </StyledBreadcrumbs>
  );
};

ClientStagesBreadcrumbs.propTypes = {
  name: PropTypes.string.isRequired,
};

export default ClientStagesBreadcrumbs;
