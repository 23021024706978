import React from "react";
import PropTypes from "prop-types";
import { Button, Grid } from "@mui/material";
import { ActionModal, InputText } from "../";
import { useUpdateExchangeApiKeyMutation } from "../../store/services/sss.api";
import { Formik } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  exchange_api_key: Yup.string().required("Api Key is required"),
  exchange_secret_key: Yup.string().required("Secret Key is required"),
});

const StageApiModal = ({ isOpen, setIsOpen, id }) => {
  const [updateExchangeApiKey] = useUpdateExchangeApiKeyMutation();

  return (
    <ActionModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={"Update Exchange Api Key"}
    >
      <>
        <Formik
          initialValues={{
            exchange_api_key: "",
            exchange_secret_key: "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            try {
              await updateExchangeApiKey({ id, ...values });
              setIsOpen(false);
            } catch (error) {
              console.error(error);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <InputText
                label={"API Key"}
                name="exchange_api_key"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.exchange_api_key}
                error={errors.exchange_api_key && touched.exchange_api_key}
                helperText={
                  errors.exchange_api_key &&
                  touched.exchange_api_key &&
                  errors.exchange_api_key
                }
              />
              <InputText
                label={"Secret Key"}
                name="exchange_secret_key"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.exchange_secret_key}
                error={
                  errors.exchange_secret_key && touched.exchange_secret_key
                }
                helperText={
                  errors.exchange_secret_key &&
                  touched.exchange_secret_key &&
                  errors.exchange_secret_key
                }
              />
              <Grid container columnGap={2}>
                <Grid item xs>
                  <Button
                    onClick={() => setIsOpen(false)}
                    fullWidth
                    variant="outlined"
                    sx={{ borderWidth: 2 }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={isSubmitting}
                  >
                    Confirm
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </>
    </ActionModal>
  );
};

StageApiModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  id: PropTypes.string,
};

export default StageApiModal;
