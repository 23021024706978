import React from "react";
import PropTypes from "prop-types";
import {
  Autocomplete,
  TextField,
  FormControl,
  InputLabel,
} from "@mui/material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import styled from "@emotion/styled";
import { COLORS } from "../styles/colors";

const Label = styled(InputLabel)({
  position: "relative",
  fontSize: 14,
  textAlign: "left",
  transform: "none",
  marginBottom: 5,
});

const InputSelect = ({
  options,
  label,
  inputLabel,
  defaultValue,
  getSelected,
  value,
  freeSolo = false,
  loading = false,
  disabled = false,
  autocompleteParams = {},
  ...otherProps
}) => {
  return (
    <FormControl fullWidth>
      {inputLabel ? <Label>{inputLabel}</Label> : null}
      <Autocomplete
        loading={loading}
        style={{ minWidth: 300 }}
        defaultValue={defaultValue}
        freeSolo={freeSolo}
        value={value}
        options={options}
        disabled={disabled}
        onChange={(event, value) => getSelected(value)}
        popupIcon={
          <KeyboardArrowDownRoundedIcon htmlColor={COLORS.base.primary} />
        }
        {...autocompleteParams}
        renderInput={(params) => (
          <TextField
            {...params}
            {...otherProps}
            variant="outlined"
            placeholder={label}
          />
        )}
      />
    </FormControl>
  );
};

InputSelect.propTypes = {
  loading: PropTypes.bool,
  inputLabel: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  defaultValue: PropTypes.object,
  value: PropTypes.object,
  getSelected: PropTypes.func,
  freeSolo: PropTypes.bool,
  disabled: PropTypes.bool,
  autocompleteParams: PropTypes.object,
};

export default InputSelect;
