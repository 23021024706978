import React, { useState } from "react";
import PropTypes from "prop-types";
import { Checkbox, TableBody, TableCell, TableRow, Link } from "@mui/material";
import { COLORS } from "../../styles/colors";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { StageActivateModal, StageDeleteModal } from "..";
import StageControls from "./StageControls";
import SystemStatus from "../SystemStatus";
import TradingStatus from "../TradingStatus";

const checkboxStyle = {
  borderRight: `1px solid ${COLORS.utility.border}`,
  width: 50,
  padding: 0,
  textAlign: "center",
};

const StageTableBody = ({ rows = [], onSelectRow, selectedRows }) => {
  const navigate = useNavigate();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openActivateModal, setOpenActivateModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const onChangeStatus = async (row) => {
    setSelectedRow(row);
    setOpenActivateModal(true);
  };

  const onDeleteStage = (row) => {
    setSelectedRow(row);
    setOpenDeleteModal(true);
  };

  const onNavigateStage = (row) => navigate(`edit/${row.id}`);

  const getSelectedRow = (selectedRows, row) => {
    if (!(selectedRow || row)) return false;
    return !!selectedRows.find((item) => item?.name === row?.name)
  }

  return (
    <TableBody>
      {rows.map((row) => {
        const isDisabled = row.system_status === "not_connected";

        return (
          <TableRow key={row?.id}>
            <TableCell padding="checkbox" sx={checkboxStyle}>
              <Checkbox
                checked={getSelectedRow(selectedRows, row)}
                onClick={() => onSelectRow(row)}
              />
            </TableCell>
            {Object.entries(row).map(([key, value]) => {
              switch (key) {
                case "id":
                  return null;

                case "name":
                  return (
                    <TableCell key={key}>
                      <Link
                        sx={{ cursor: "pointer" }}
                        onClick={() => onNavigateStage(row)}
                      >
                        {value ?? '-'}
                      </Link>
                    </TableCell>
                  );

                case "system_status":
                  return (
                    <TableCell key={key}><SystemStatus status={value} /></TableCell>
                  );

                case "client":
                  return <TableCell key={key}>{value?.name ?? '-'}</TableCell>;

                case "last_activity_at":
                  return (
                    <TableCell key={key} sx={{ color: isDisabled && COLORS.text.disabled }}>
                      {value ? format(value, "dd.MM.yy HH:mm:ss") : "-"}
                    </TableCell>
                  );

                case "q_days":
                case "start_date":
                case "q_active_strategies":
                  return (
                    <TableCell key={key} sx={{ color: isDisabled && COLORS.text.disabled }}>
                      {value ?? "-"}
                    </TableCell>
                  );

                case "trading_status":
                  return (
                    <TableCell key={key} >
                      <TradingStatus status={value} disabled={isDisabled} />
                    </TableCell>
                  );

                default:
                  return <TableCell key={key}>{value ?? "-"}</TableCell>;
              }
            })}

            <StageControls
              stageId={row.id}
              status={row.trading_status}
              configured={row.system_status}
              onChageStatus={() => onChangeStatus(row)}
              onDeleteStage={() => onDeleteStage(row)}
              disabled={isDisabled}
            />
          </TableRow>
        );
      })}
      <StageDeleteModal
        isOpen={openDeleteModal}
        setIsOpen={() => setOpenDeleteModal(!openDeleteModal)}
        stage={selectedRow}
      />
      <StageActivateModal
        isOpen={openActivateModal}
        setIsOpen={() => setOpenActivateModal(!openActivateModal)}
        stage={selectedRow}
      />
    </TableBody>
  );
};

StageTableBody.propTypes = {
  rows: PropTypes.array,
  onSelectRow: PropTypes.func,
  selectedRows: PropTypes.array,
};

export default StageTableBody;