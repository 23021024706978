import React, { useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { Button, Grid } from "@mui/material";
import {
  TableFilter,
  InputSelect,
  FilterDropdown,
  InputMultiselect,
  FilterButton,
} from "../";
import { useSearchStageByNameQuery } from "../../store/services/sss.api";
import { FilterIcon, GridIcon } from "../../assets";
import StageTableSelected from "./StageTableSelected";
import { optionsExchange } from "../../utils/consts";
import ClientFilterInput from "../ClientFilterInput";

const initialFilters = {
  selectedStages: [],
  selectedClients: [],
  selectedExchange: "",
};

const StageTableFilter = ({
  setFilterStage,
  setFilterClient,
  setFilterExchange,
  cells,
  setCellExclusion,
  excludedCells,
  selectedRows = [],
}) => {
  const [openFilter, setOpenFilter] = useState(false);
  const toggleFilter = useCallback(
    () => setOpenFilter((prevState) => !prevState),
    [],
  );

  const [queryStage, setQueryStage] = useState("");

  const [filters, setFilters] = useState(initialFilters);

  const getStagesName = useSearchStageByNameQuery({ name: queryStage });

  const onSearchStage = useCallback((value) => setQueryStage(value || ""), []);

  const onSelectStage = useCallback(
    (value) =>
      setFilters((prev) => ({
        ...prev,
        selectedStages: value || [],
      })),
    [],
  );

  const onSelectClient = useCallback(
    (value) =>
      setFilters((prev) => ({
        ...prev,
        selectedClients: value || [],
      })),
    [],
  );

  const onSelectExchange = useCallback(
    (value) =>
      setFilters((prev) => ({
        ...prev,
        selectedExchange: value?.name || "",
      })),
    [],
  );

  const optionsStage = useMemo(
    () =>
      getStagesName?.data
        ? getStagesName.data.map((item) => ({ label: item.name, ...item }))
        : [],
    [getStagesName],
  );

  const applyFilters = useCallback(() => {
    setFilterStage(filters.selectedStages);
    setFilterClient(filters.selectedClients);
    setFilterExchange(filters.selectedExchange);
  }, [filters, setFilterStage, setFilterClient, setFilterExchange]);

  const clearFilters = useCallback(() => {
    setFilters(initialFilters);
    setFilterStage([]);
    setFilterClient([]);
    setFilterExchange("");
  }, [setFilterStage, setFilterClient, setFilterExchange]);

  return (
    <>
      <TableFilter>
        <StageTableSelected selectedRows={selectedRows} />
        <Grid item>
          <FilterButton
            onClick={toggleFilter}
            icon={<img src={FilterIcon} alt="checkbox" />}
          />
        </Grid>
        <Grid item>
          <FilterDropdown
            getSelected={setCellExclusion}
            excludedCells={excludedCells}
            options={cells}
            icon={<img src={GridIcon} alt="grid icon" />}
          />
        </Grid>
      </TableFilter>
      <TableFilter
        style={{ borderRadius: 0, display: openFilter ? "flex" : "none" }}
      >
        <Grid item>
          <InputMultiselect
            loading={getStagesName.isLoading || getStagesName.isFetching}
            options={optionsStage}
            label={"Stage"}
            value={filters.selectedStages}
            handleInputChange={onSearchStage}
            getSelected={onSelectStage}
          />
        </Grid>
        <Grid item>
          <ClientFilterInput
            value={filters.selectedClients}
            getSelected={onSelectClient}
          />
        </Grid>
        <Grid item>
          <InputSelect
            options={optionsExchange}
            label={"Exchange"}
            defaultValue={null}
            value={
              optionsExchange.filter((o) => o.name === filters.selectedExchange)
                ?.label
            }
            getSelected={onSelectExchange}
          />
        </Grid>
        <Grid item xs />
        <Grid item>
          <Button variant="outlined" onClick={clearFilters}>
            Clear All
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={applyFilters}>
            Apply
          </Button>
        </Grid>
      </TableFilter>
    </>
  );
};

StageTableFilter.propTypes = {
  setFilterStage: PropTypes.func,
  setFilterClient: PropTypes.func,
  setFilterExchange: PropTypes.func,
  selectedRows: PropTypes.array,
  cells: PropTypes.array,
  setCellExclusion: PropTypes.func,
  excludedCells: PropTypes.array,
};

export default StageTableFilter;
