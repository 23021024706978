import React from "react";
import {
  ApaPage,
  AmdPage,
  SssPage,
  SssEditPage,
  ClientPage,
  SssHistoryPage,
  ClientStagesPage,
} from "../pages";
import { PrivateRoute } from "../components";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet,
} from "react-router-dom";

const Navigation = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Outlet />} />
        <Route
          index
          element={
            <PrivateRoute>
              <AmdPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/active-position"
          element={
            <PrivateRoute>
              <ApaPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/stages-system"
          element={
            <PrivateRoute>
              <SssPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/stages-system/edit/:id"
          element={
            <PrivateRoute>
              <SssEditPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/stages-system/history/:id"
          element={
            <PrivateRoute>
              <SssHistoryPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/clients"
          element={
            <PrivateRoute>
              <ClientPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/clients/stages/:id"
          element={
            <PrivateRoute>
              <ClientStagesPage />
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default Navigation;
